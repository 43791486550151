import {
  guardHandler,
  HandlerMap,
  isSystemDboBatchCreatedEvent,
  isSystemDboBatchUpdatedEvent,
  SystemDboBatchCreatedEvent,
  SystemDboBatchUpdatedEvent,
} from '@propra-system/registry';
import { ProgressMessageTemplate } from 'api';
import { useEvents } from 'context';
import { DateTime, Duration } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { safeRound } from 'system';

type UseDboBatchEventsProps = {
  staleTimeout?: Duration;
  predicate?: (
    detail: (SystemDboBatchCreatedEvent | SystemDboBatchUpdatedEvent)['detail']
  ) => boolean;
  onProgress?: (
    detail: (SystemDboBatchCreatedEvent | SystemDboBatchUpdatedEvent)['detail']
  ) => void;
  onDone?: (detail: (SystemDboBatchCreatedEvent | SystemDboBatchUpdatedEvent)['detail']) => void;
};

export type BatchProgressFields = {
  done?: boolean;
  stale?: boolean;
  progress: number;
  batchId?: string;
  updatedZ?: string;
  messageTemplate?: ProgressMessageTemplate;
};

export const useDboBatchEvents = (
  { predicate, onProgress, onDone, staleTimeout }: UseDboBatchEventsProps,
  { skip }: { skip?: boolean } = {}
) => {
  const { addHandlerMap, removeHandlerMap } = useEvents();
  const [batchProgress, setBatchProgess] = useState<BatchProgressFields>({
    done: false,
    progress: 0,
    updatedZ: DateTime.now().toISO(),
  });

  const onDoneRef = useRef(onDone);
  useEffect(() => {
    onDoneRef.current = onDone;
  }, [onDone]);

  const predicateRef = useRef(predicate);
  useEffect(() => {
    predicateRef.current = predicate;
  }, [predicate]);

  const onProgressRef = useRef(onProgress);
  useEffect(() => {
    onProgressRef.current = onProgress;
  }, [onProgress]);

  useEffect(() => {
    const handlerMap = [
      guardHandler(isSystemDboBatchCreatedEvent, async ({ detail }) => {
        if (!predicateRef.current || predicateRef.current(detail)) {
          onProgressRef.current?.(detail);
          setBatchProgess((p) => ({
            ...p,
            ...((!p.batchId || p.batchId === detail.batchId) && {
              batchId: detail.batchId,
              done: false,
              progress: 0,
              updatedZ: DateTime.now().toISO(),
            }),
          }));
        }
      }),
      guardHandler(
        isSystemDboBatchUpdatedEvent,
        async ({
          detail: { totalMessageCount = 1, processedMessageCount = 0, done = false, ...detail },
        }) => {
          if (!predicateRef.current || predicateRef.current(detail)) {
            setBatchProgess(({ progress, ...rest }) => {
              const newProgress = safeRound((processedMessageCount / totalMessageCount) * 100);

              return {
                ...rest,
                progress,
                batchId: detail.batchId,
                updatedZ: DateTime.now().toISO(),
                ...((newProgress >= progress || !rest.done) && {
                  done,
                  progress: Math.max(progress, newProgress),
                }),
              };
            });

            if (done) {
              onDoneRef.current?.(detail);
            } else {
              onProgressRef.current?.(detail);
            }
          }
        }
      ),
    ] as HandlerMap;

    if (!skip) {
      addHandlerMap(handlerMap);
    }

    return () => {
      removeHandlerMap(handlerMap);
    };
  }, [addHandlerMap, removeHandlerMap, skip]);

  const resetBatchProgress = useCallback(() => {
    setBatchProgess(() => ({ done: false, progress: 0 }));
  }, []);

  const completeBatchProgress = useCallback(() => {
    setBatchProgess((p) => ({ ...p, done: true, progress: 100 }));
  }, []);

  const isBatchStale =
    staleTimeout && batchProgress.updatedZ && batchProgress.done !== true
      ? DateTime.fromISO(batchProgress.updatedZ).diff(DateTime.now()) >= staleTimeout
      : false;

  return { batchProgress, isBatchStale, resetBatchProgress, completeBatchProgress };
};
