import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';
import {
  RequestsSortField,
  RequestsSortInput,
  SortDirection,
  useGetOperatorRequestHistoryQuery,
} from 'api';
import equal from 'fast-deep-equal/es6';
import { usePrevious } from 'hooks/usePrevious';
import { useRelayPage } from 'hooks/useRelayPage';
import { usePageMapping } from 'pages/accounting/hooks/usePageMapping';
import { useRefetch } from 'pages/accounting/hooks/useRefetch';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { emptyArray } from 'system';

export const OPERATOR_REQUEST_HISTORY_PAGE_SIZE = 25;

const initialPaginationModel: GridPaginationModel = {
  page: 0,
  pageSize: OPERATOR_REQUEST_HISTORY_PAGE_SIZE,
};

export const useServerOperatorRequestHistory = ({
  id,
  defaultSortModel = emptyArray,
  skip,
}: {
  id: string;
  defaultSortModel?: GridSortModel;
  skip?: boolean;
}) => {
  const [paginationModel, setPaginationModel] = useState(initialPaginationModel);

  const { getEndCursor, putPageInfo, resetMapping, getPageInfo } = usePageMapping();

  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const handleSortModelChange = useCallback(
    (newModel: GridSortModel) => {
      setSortModel((oldModel) => {
        if (equal(oldModel, newModel)) return oldModel;
        resetMapping();
        setPaginationModel(initialPaginationModel);
        return newModel;
      });
    },
    [resetMapping]
  );

  const sort: RequestsSortInput[] | undefined = useMemo(() => {
    const sortItems = sortModel
      .map((s) => ({
        field: s.field as RequestsSortField,
        direction: (s.sort as SortDirection | null | undefined) ?? SortDirection.Asc,
      }))
      .filter(
        (s) =>
          Object.values(RequestsSortField).includes(s.field) &&
          Object.values(SortDirection).includes(s.direction)
      );
    return sortItems.length > 0 ? sortItems : undefined;
  }, [sortModel]);

  const initialPaginationModelChanged = !equal(
    initialPaginationModel,
    usePrevious(initialPaginationModel)
  );

  useEffect(() => {
    if (initialPaginationModelChanged) {
      resetMapping();
    }
  }, [initialPaginationModelChanged, resetMapping]);

  const [requests, { edges, pageInfo, loading, ...requestsMeta }] = useRelayPage(
    useGetOperatorRequestHistoryQuery,
    {
      getPage: (d) => d?.operator?.pageRequests,
      skip,
      variables: {
        id,
        after: getEndCursor(paginationModel.page),
        first: OPERATOR_REQUEST_HISTORY_PAGE_SIZE,
        sort,
        filter: {
          visitStatus: ['COMPLETED'],
        },
      },
    }
  );

  const totalCount = requestsMeta.totalCount ?? 0;

  const { handleRefresh } = useRefetch(requestsMeta);

  const handlePaginationModelChange = useCallback((newPaginationModel: GridPaginationModel) => {
    setPaginationModel((oldPaginationModel) => {
      return !equal(oldPaginationModel, newPaginationModel)
        ? newPaginationModel
        : oldPaginationModel;
    });
  }, []);

  useEffect(() => {
    if (!loading && pageInfo) {
      putPageInfo(paginationModel.page, pageInfo);
    }
  }, [paginationModel.page, loading, pageInfo, putPageInfo]);

  return {
    handleRefresh,
    sortModel,
    handleSortModelChange,
    paginationModel,
    handlePaginationModelChange,
    pageSize: OPERATOR_REQUEST_HISTORY_PAGE_SIZE,
    requests,
    loading,
    rowCount: totalCount,
    getPageInfo,
    totalCount,
  };
};
