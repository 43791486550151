import { Add, Delete } from '@mui/icons-material';
import { Button, InputAdornment, ListItemText, Stack, TextField } from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridOverlay,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { AccountType } from 'api';
import {
  AutocompleteField,
  CheckboxField,
  CurrencyField,
  GridSearchToolbar,
  Modal,
  PercentField,
} from 'components';
import { useModalControl } from 'hooks/useModalControl';
import { compact, findIndex, map } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Categories, Option, capitalize, formatCurrency, formatPercentage } from 'system';
import { useGlAccounts, useGlMapping } from '../hooks';
import { BillingRateFormFields } from '../hooks/useFinancialSettings';
import { formatAccountName } from '../utils';

type BillingRateRowModel = BillingRateFormFields & {
  id: string;
};

const RateModal = ({
  onHideModal,
  showModal,
  onAdd,
  otherCategories,
}: {
  onHideModal: VoidFunction;
  showModal: boolean;
  onAdd: (row: BillingRateRowModel) => void;
  otherCategories: (Categories | string)[];
}) => {
  const { mappedGlId } = useGlMapping();
  const { glAccounts } = useGlAccounts();
  const { watch } = useFormContext();
  const defaultBillingRates: BillingRateRowModel = watch('billingRates');
  const defaultGlId = mappedGlId('maintenanceExpense');
  const glOptions = glAccounts
    .filter(({ accountType }) => accountType === AccountType.Expense)
    .map((account) => ({ text: formatAccountName(account), id: account.id }));
  const categoryOptions = Object.values(Categories)
    .filter((category) => !otherCategories.includes(category))
    .map((category) => ({ text: capitalize(category), id: category }));
  const [row, setRow] = useState<BillingRateRowModel>({
    id: 'new',
    category: categoryOptions[0].id,
    glId: defaultGlId,
    labourRate: defaultBillingRates.labourRate,
    materialsMarkup: defaultBillingRates.materialsMarkup,
    requireApproval: defaultBillingRates.requireApproval,
    minBillableMinutes: defaultBillingRates.minBillableMinutes,
    enabled: defaultBillingRates.enabled,
    trackRevenue: defaultBillingRates.trackRevenue,
  });

  const addAction = {
    label: 'Add',
    onClick: () => {
      onAdd(row);
      onHideModal();
    },
  };

  return (
    <Modal
      onClose={onHideModal}
      open={showModal}
      title="Customize category billing"
      actions={{
        cancel: { label: 'Cancel', onClick: onHideModal },
        confirm: addAction,
      }}
    >
      <Stack spacing={2}>
        <AutocompleteField
          options={categoryOptions}
          label="Category"
          size="medium"
          value={row.category}
          onChange={(_e, value) => setRow((prev) => ({ ...prev, category: (value as Option)?.id }))}
        />
        <AutocompleteField
          options={glOptions}
          label="Account"
          size="medium"
          value={row.glId}
          onChange={(_e, value) => setRow((prev) => ({ ...prev, glId: (value as Option)?.id }))}
        />
        <CurrencyField
          label="Labour Rate"
          value={Number(row.labourRate)}
          onValueChange={(e) => setRow((prev) => ({ ...prev, labourRate: e.value || '0' }))}
        />
        <PercentField
          label="Material Markup"
          value={row.materialsMarkup}
          onValueChange={(e) => setRow((prev) => ({ ...prev, materialsMarkup: e.value || '0' }))}
        />
        <CheckboxField
          label="Require Approval"
          size="small"
          checked={row.requireApproval}
          onChange={(e) => setRow((prev) => ({ ...prev, requireApproval: e.target.checked }))}
        />

        <TextField
          size="medium"
          type="number"
          variant="filled"
          InputProps={{
            endAdornment: <InputAdornment position="end">Minutes</InputAdornment>,
          }}
          value={row.minBillableMinutes}
          onChange={(e) =>
            setRow((prev) => ({ ...prev, minBillableMinutes: Number(e.target.value) }))
          }
        />
      </Stack>
    </Modal>
  );
};

export const CustomBillingRatesTable = () => {
  const { glAccounts, glAccountName } = useGlAccounts();
  const expenseAccounts = glAccounts.filter(
    ({ accountType }) => accountType === AccountType.Expense
  );
  const [showModal, hideModal, modalOpen] = useModalControl();
  const { fields, append, remove } = useFieldArray<{ customBillingRates: BillingRateRowModel[] }>({
    name: 'customBillingRates',
  });

  const apiRef = useGridApiRef();
  const rows = fields;
  const rowIndex = useCallback(
    (row: BillingRateRowModel) => findIndex(fields, { id: row.id }),
    [fields]
  );

  const categoryOptions = useMemo(
    () =>
      Object.values(Categories).filter((category) => !fields.some((f) => f.category === category)),
    [fields]
  );

  const allCategoriesMapped = categoryOptions.length === 0;

  const columns: Array<GridColDef<BillingRateRowModel>> = [
    {
      field: 'category',
      headerName: 'Category',
      type: 'singleSelect',
      valueFormatter: (value: string | undefined) => capitalize(value ?? ''),
      valueOptions: Object.values(Categories),
      flex: 1,
    },
    {
      field: 'glId',
      headerName: 'Account',
      type: 'singleSelect',
      valueFormatter: (value: string | undefined) => glAccountName(value),
      valueOptions: expenseAccounts.map(({ id, name }) => ({ label: name, value: id })),
      flex: 1,
    },
    {
      field: 'labourRate',
      headerName: 'Labour Rate',
      type: 'number',
      valueFormatter: (value: number | undefined) => formatCurrency(value),
      flex: 1,
    },
    {
      field: 'materialsMarkup',
      headerName: 'Material Markup',
      type: 'number',
      valueFormatter: (value: number | undefined) => formatPercentage(Number(value) / 100),
      flex: 1,
    },
    {
      field: 'requireApproval',
      headerName: 'Require Approval',
      type: 'boolean',
      flex: 1,
    },
    {
      field: 'minBillableMinutes',
      headerName: 'Minimum minutes',
      type: 'number',
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<Delete />}
          label="Remove"
          title="Remove customization"
          onClick={() => remove(rowIndex(row))}
        />,
      ],
    },
  ];

  return (
    <DataGridPro<BillingRateRowModel>
      {...{ rows, columns, apiRef }}
      sx={{ border: 'none' }}
      autoHeight
      hideFooter
      disableMultipleRowSelection
      isRowSelectable={() => false}
      initialState={{ sorting: { sortModel: [{ field: 'category', sort: 'asc' as const }] } }}
      slots={{
        toolbar: GridSearchToolbar,
        noRowsOverlay: GridOverlay,
      }}
      slotProps={{
        toolbar: {
          left: (
            <ListItemText
              primary="Customize settings by request category"
              primaryTypographyProps={{ variant: 'body1' }}
              secondary="The settings specified here for particular category will override the default account-wide settings above"
            />
          ),
          right: (
            <>
              <Button startIcon={<Add />} disabled={allCategoriesMapped} onClick={showModal}>
                Add category
              </Button>
              {modalOpen && (
                <RateModal
                  onHideModal={hideModal}
                  showModal={modalOpen}
                  onAdd={append}
                  otherCategories={compact(map(fields, 'category'))}
                />
              )}
            </>
          ),
        },
        noRowsOverlay: {
          children: 'No customizations. All requests will use the default settings above',
        },
      }}
    />
  );
};
