import Intercom from '@intercom/messenger-js-sdk';
import { useSessionContext } from 'Session';

export const IntercomMesssenger = () => {
  const { user } = useSessionContext();
  const createdAt = Math.floor(+new Date(user.createdZ) / 1000);
  const accountCreatedAt = Math.floor(+new Date(user.accountCreatedZ) / 1000);

  Intercom({
    app_id: 'pmm0qnek',
    user_id: user.id,
    name: user.name,
    email: user.email,
    created_at: createdAt,
    user_hash: user.intercomHmac,
    company: {
      id: user.accountId,
      name: user.accountName,
      created_at: accountCreatedAt,
    },
    replay_link: user.replayLink,
  });
  return <></>;
};
