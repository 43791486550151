import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import { Alert, Box, Skeleton, Stack } from '@mui/material';
import {
  GridPagination,
  GridToolbarContainer,
  gridSortedRowEntriesSelector,
  selectedGridRowsCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { ensureArray } from '@propra-system/util/ensureArray';
import { isEmpty } from 'lodash';
import { FieldErrors, FieldName, FieldValues } from 'react-hook-form';

export type GridErrorFooterProps<T extends FieldValues = Record<string, unknown>> = {
  loading?: boolean;
  hideCounts?: boolean;
  errors?: FieldErrors<T> | undefined;
  names?: Array<FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>>;
};

export const GridErrorFooter = <T extends FieldValues = Record<string, unknown>>({
  errors,
  names,
  loading,
  hideCounts,
}: GridErrorFooterProps<T>) => {
  const apiRef = useGridApiContext();
  const selectedRowCount = useGridSelector(apiRef, selectedGridRowsCountSelector);
  const rows = useGridSelector(apiRef, gridSortedRowEntriesSelector);

  return (
    <Stack>
      {!isEmpty(errors) && (
        <Stack sx={{ maxHeight: '150px', overflow: 'auto' }}>
          {ensureArray(names).map((name) => (
            <ErrorMessage
              key={name}
              errors={errors}
              name={name}
              render={({ message }) =>
                message && (
                  <Alert severity="error" sx={{ whiteSpace: 'pre-wrap' }}>
                    {message}
                  </Alert>
                )
              }
            />
          ))}
        </Stack>
      )}

      {hideCounts ? (
        <GridToolbarContainer
          sx={{ alignItems: 'center', justifyContent: 'flex-end', minHeight: 52, p: 2 }}
        >
          <GridPagination />
        </GridToolbarContainer>
      ) : (
        <Stack direction="row" justifyContent="space-between">
          <GridToolbarContainer
            sx={{ alignItems: 'center', justifyContent: 'space-between', minHeight: 52, p: 2 }}
          >
            {loading ? (
              <Skeleton />
            ) : (
              <Box>
                {rows.length} rows
                {Boolean(selectedRowCount) && <>, {selectedRowCount} selected</>}
              </Box>
            )}
          </GridToolbarContainer>

          <GridToolbarContainer
            sx={{ alignItems: 'center', justifyContent: 'flex-end', minHeight: 52, p: 2 }}
          >
            <GridPagination />
          </GridToolbarContainer>
        </Stack>
      )}
    </Stack>
  );
};
