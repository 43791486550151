import {
  GetProvinceTaxDetailsInput,
  TaxCategory,
  useGetProvinceTaxDetailsLazyQuery,
  useGetProvinceTaxDetailsQuery,
} from 'api';
import { useCallback } from 'react';
import { ensureArray } from 'system';
import { useMeta } from './useMeta';

export const useGetProvinceTaxDetails = (input?: GetProvinceTaxDetailsInput) => {
  const [getTaxDetailsByProvince] = useGetProvinceTaxDetailsLazyQuery();

  const { data, ...meta } = useGetProvinceTaxDetailsQuery({
    variables: { input },
    skip: !input,
  });

  const { loading } = useMeta(meta);
  const taxDetails = ensureArray(data?.getProvinceTaxDetails?.taxDetails);

  const getTaxDetails = useCallback(
    async ({
      province,
      postal,
      country,
      category,
      taxExempted,
    }: {
      province?: string;
      postal?: string;
      country?: string;
      category?: TaxCategory;
      taxExempted?: boolean;
    }) => {
      const { data } = await getTaxDetailsByProvince({
        variables: { input: { province, postal, country, taxExempted, category } },
      });

      return ensureArray(data?.getProvinceTaxDetails?.taxDetails);
    },
    [getTaxDetailsByProvince]
  );

  return {
    loading,
    taxDetails,
    getTaxDetails,
  };
};
