import { TextFieldProps } from '@mui/material';
import { DatePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

export default function YearFieldController<T extends FieldValues>({
  name,
  label,
  maxDate,
  disableFuture,
  className,
  pickerVariant,
  fullWidth = true,
}: TextFieldProps &
  Partial<Parameters<typeof DatePicker>[0]> & { name: Path<T>; pickerVariant?: 'inline' }) {
  const { control } = useFormContext<T>();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          {pickerVariant === 'inline' ? (
            <DesktopDatePicker
              {...field}
              value={field.value ? new AdapterLuxon().date(field.value) : null}
              {...{ maxDate, disableFuture }}
              views={['year']}
              onChange={(date) => {
                field.onChange(date?.year, { shouldValidate: true });
              }}
              slotProps={{
                textField: {
                  type: 'text',
                  variant: 'filled',
                  fullWidth,
                  label,
                  helperText: fieldState.error?.message,
                  error: Boolean(fieldState.error?.message),
                  className,
                },
              }}
            />
          ) : (
            <DatePicker
              {...field}
              value={field.value ? new AdapterLuxon().date(field.value) : null}
              {...{ maxDate, disableFuture }}
              views={['year']}
              onChange={(date) => {
                field.onChange(date?.year, { shouldValidate: true });
              }}
              slotProps={{
                textField: {
                  type: 'text',
                  variant: 'filled',
                  fullWidth,
                  label,
                  helperText: fieldState.error?.message,
                  error: Boolean(fieldState.error?.message),
                  InputLabelProps: { shrink: Boolean(field.value) },
                  className,
                },
              }}
            />
          )}
        </LocalizationProvider>
      )}
    />
  );
}
