import {
  JournalEntryFieldsFragment,
  UpdateJournalEntryInput,
  useGetJournalEntryQuery,
  useUpdateJournalEntryMutation,
} from 'api';
import { useMeta } from 'hooks/useMeta';
import { useNotification } from 'hooks/useNotification';
import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { stripNonData } from 'system';
import { fromCreditDebitToAmount } from '../utils';
import { JournalEntryFormFields } from './useAddJournalEntries';
import { useGlAccounts } from './useGlAccounts';

export const useEditJournalEntries = (id: string) => {
  const navigate = useNavigate();
  const { sendNotification } = useNotification();
  const { findGlAccount } = useGlAccounts();

  const [updateJournalMutation, updateMeta] = useUpdateJournalEntryMutation();
  const { data: { journalEntry } = {}, ...getMeta } = useGetJournalEntryQuery({
    variables: { id },
    skip: !id,
    fetchPolicy: 'network-only',
  });
  const { loading } = useMeta(getMeta, updateMeta);

  const editedJournalsRef = useRef<JournalEntryFieldsFragment[] | null>(null);
  const getEditedJournals = useCallback(() => {
    const { current: journals } = editedJournalsRef;
    editedJournalsRef.current = null;

    return journals;
  }, []);

  const editJournalEntries = async (
    { propertyId, ownerId, ref, ...values }: JournalEntryFormFields,
    { redirect = true } = {}
  ) => {
    if (propertyId && ownerId) {
      try {
        const input: UpdateJournalEntryInput = stripNonData({
          id,
          ...values,
          lines: values.lines.map(({ credit, debit, ...line }) => ({
            ...line,
            propertyId,
            ownerId,
            amount: fromCreditDebitToAmount({ credit, debit }, findGlAccount(line.glId)),
            ref,
          })),
          ref,
        });

        const result = await updateJournalMutation({
          variables: { input },

          update(cache, { data }) {
            if (data?.updateJournalEntry?.success && data.updateJournalEntry.journalIds) {
              data.updateJournalEntry.journalIds.forEach((journalId) =>
                cache.evict({ id: cache.identify({ id: journalId, __typename: 'JournalEntry' }) })
              );

              cache.gc();
            }
          },
        });

        sendNotification('Entry updated', 'success');
        editedJournalsRef.current = result.data?.updateJournalEntry.journalEntries ?? null;

        if (redirect) {
          navigate(`/accounting/accounts/${journalEntry?.glId}`);
        }

        return true;
      } catch (e) {
        sendNotification('An error happened', 'error');
      }
    } else {
      sendNotification('Must select property and owner', 'error');
    }

    return false;
  };

  return {
    loading,
    journalEntry,
    fetching: getMeta.loading,
    editJournalEntries,
    getEditedJournals,
  };
};
