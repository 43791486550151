import { QueryHookOptions } from '@apollo/client';
import {
  BatchTask,
  GetBooksBatchTaskQuery,
  ProgressMessageTemplate,
  useGetBooksBatchTaskQuery,
} from 'api';
import { useInterval } from 'hooks/useInterval';
import { useMeta } from 'hooks/useMeta';
import { usePrevious } from 'hooks/usePrevious';
import { useTimeout } from 'hooks/useTimeout';
import { isEqual } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { safeRound } from 'system';

const getBatchProgress = (
  result?: BatchTask,
  stale?: boolean
): {
  done?: boolean;
  stale?: boolean;
  progress: number;
  batchId?: string;
  updatedZ?: string;
  messageTemplate?: ProgressMessageTemplate;
} => {
  if (!result) {
    return { progress: 0, done: false, stale };
  }

  const { taskProgress } = result;
  const progress = safeRound((taskProgress.processed ?? 0) / Math.max(taskProgress.total, 1)) * 100;

  return {
    stale,
    progress,
    batchId: result.id,
    done: taskProgress.done ?? progress >= 100,
    messageTemplate: result?.taskProgress.messageTemplate,
  };
};

type UseBooksBatchTaskProps = {
  id?: string;
  untilCompleted?: boolean;
  pollInterval?: number;
  staleTimeout?: Duration;
  onCompleted?: (data: ReturnType<typeof getBatchProgress>) => void;
} & Omit<
  QueryHookOptions<GetBooksBatchTaskQuery>,
  'variables' | 'defaultFetchPolicy' | 'nextFetchPolicy' | 'defaultOptions' | 'onCompleted'
>;

export const useBooksBatchTask = ({
  id = '',
  untilCompleted,
  pollInterval = 1000,
  staleTimeout,
  skip: rootSkip = false,
  onCompleted,
  ...options
}: UseBooksBatchTaskProps) => {
  const [stale, setStale] = useState(false);
  const [interval, setInterval] = useState<number | undefined>(pollInterval);
  const [staleISO, setStaleISO] = useState(
    staleTimeout && DateTime.now().plus(staleTimeout).toISO()
  );

  const resolveBachTaskProgress = useCallback(
    (data?: GetBooksBatchTaskQuery) =>
      rootSkip
        ? { progress: 100, done: true }
        : getBatchProgress(data?.account?.books?.batchTask, stale),
    [rootSkip, stale]
  );

  const skip = !id || rootSkip;
  const { data, refetch, ...meta } = useGetBooksBatchTaskQuery({
    variables: { id },
    skip,
    notifyOnNetworkStatusChange: true,
    initialFetchPolicy: 'standby',
    fetchPolicy: 'cache-and-network',
    ...options,
    ...(onCompleted && {
      onCompleted: (d) => onCompleted?.(resolveBachTaskProgress(d)),
    }),
  });

  const taskProgress = useMemo(
    () => resolveBachTaskProgress(data),
    [data, resolveBachTaskProgress]
  );
  const { loading } = useMeta(meta);

  const previousProgress = usePrevious(taskProgress);
  const shouldPoll = !skip && untilCompleted && !taskProgress.done;

  useTimeout(
    () => {
      setStale(true);
      setInterval(undefined);
    },
    { delay: shouldPoll ? staleISO : undefined }
  );

  useInterval(
    () => {
      refetch();
      setStaleISO(
        isEqual(previousProgress, taskProgress) && staleTimeout
          ? DateTime.now().plus(staleTimeout).toISO()
          : undefined
      );
    },
    shouldPoll ? interval : undefined
  );

  return {
    loading,
    taskProgress,
  };
};
