import { ReactiveVar, makeVar } from '@apollo/client';
import { ClickAwayListener } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridCell,
  GridCellModes,
  GridCellProps,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowParams,
  gridClasses,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { ensureArray } from '@propra-system/util/ensureArray';
import { gridInitialState } from 'cache/dataGrid';
import { AutoSizeBox, AutoSizeBoxProps } from 'components/AutoSizeBox';
import { GridFooter } from 'components/GridFooter';
import { GridSearchToolbar } from 'components/GridToolbar';
import { useGridState } from 'hooks/useGridState';
import plur from 'plur';
import { ComponentType, Fragment, forwardRef, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ZodError, z } from 'zod';

const elementWithin = (
  element: Element,
  match: string,
  { untilMatches }: { untilMatches?: string } = {}
) => {
  let current: Element | null = element;

  while (current) {
    if (current.matches(match)) {
      return true;
    }

    if (untilMatches && current.matches(untilMatches)) {
      break;
    }

    current = current.parentElement;
  }

  return false;
};

export type DataGridProps<TRow extends GridRowModel = GridRowModel> = {
  tableState?: ReactiveVar<GridInitialStatePro>;
  autoRowHeight?: boolean;
  editableCellEvents?: boolean;
  disableOnClickAwaySave?: boolean;
  disableFilter?: boolean;
  rowSchema?: z.ZodType<TRow>;
  onBeforeRowUpdate?: (index: number, id: GridRowId) => void | Promise<void>;
  onAfterRowUpdate?: (index: number, row: TRow) => void | Promise<void>;
  onRowSchemaError?: (index: number, error: ZodError<TRow>) => void;
  getRowNavigation?: (params: GridRowParams<TRow>) => string;

  useFragmentContainer?: boolean;
  slots?: DataGridProProps<TRow>['slots'] & { gridContainer?: ComponentType<AutoSizeBoxProps> };
  slotProps?: DataGridProProps<TRow>['slotProps'] & { gridContainer?: AutoSizeBoxProps };
} & Omit<DataGridProProps<TRow>, 'slotProps' | 'slots'>;

// GridRenderEditCellParams?
const SaveOnClickAwayCell = forwardRef<HTMLDivElement, GridCellProps>((props, ref) => {
  const apiRef = useGridApiContext();
  const rowId = props.rowId;
  const cellMode = apiRef.current.getCellMode(rowId, props.column.field);

  return cellMode === GridCellModes.Edit ? (
    <ClickAwayListener
      onClickAway={(e) => {
        const { target } = e;
        if (target instanceof Element) {
          const isWithinRow = elementWithin(target, `[data-id="${rowId}"]`, {
            untilMatches: `.${gridClasses.row}`,
          });

          const isWithinRowsPanel = elementWithin(
            target,
            `[data-id="${rowId}"].${gridClasses['row--detailPanelExpanded']} + .${gridClasses.detailPanel}`
          );

          if (isWithinRow || isWithinRowsPanel) {
            e.stopPropagation();
            (e as { defaultMuiPrevented?: boolean }).defaultMuiPrevented = true;
          } else {
            apiRef.current.stopRowEditMode({ id: props.rowId, ignoreModifications: false });
          }
        }
      }}
    >
      <GridCell ref={ref} {...props} />
    </ClickAwayListener>
  ) : (
    <GridCell ref={ref} {...props} />
  );
});

class RowSchemaError extends Error {
  constructor(
    public index: number,
    public error: ZodError
  ) {
    super(`Row ${index} has ${plur('error', Object.values(error.flatten()).flat().length)}`);
  }
}

const getRowHeight: DataGridProps['getRowHeight'] = () => 'auto';

export default function DataGrid<TRow extends GridRowModel = GridRowModel>({
  tableState,
  editableCellEvents,
  disableOnClickAwaySave,
  rowSchema,
  slots: { gridContainer, ...slots } = {},
  slotProps: { gridContainer: gridContainerProps, ...slotProps } = {},
  autoRowHeight = true,
  onBeforeRowUpdate,
  onAfterRowUpdate,
  onRowSchemaError,
  disableFilter,
  sx,
  getRowNavigation,
  useFragmentContainer,
  density: controlledDensity = 'compact',
  onDensityChange,
  ...props
}: DataGridProps<TRow>) {
  const navigate = useNavigate();
  const defaultTableState = useMemo(() => makeVar<GridInitialStatePro>(gridInitialState()), []);
  const { initialState, apiRef } = useGridState(tableState ?? defaultTableState, {
    apiRef: props.apiRef,
  });

  const tableStateRef = useRef(tableState);
  const initialStateRef = useRef(initialState);

  // Initialize table state from props, for controlled properties
  useEffect(() => {
    const initialStateValues = initialStateRef.current;

    if (controlledDensity && !initialStateValues?.density) {
      apiRef.current.setDensity(controlledDensity);
      tableStateRef.current?.({ ...initialStateValues, density: controlledDensity });
    }
  }, [apiRef, controlledDensity]);

  const onCellKeyDown = useMemo<GridEventListener<'cellKeyDown'> | undefined>(() => {
    if (editableCellEvents) {
      return ({ id, field, cellMode, ...rest }, event, detail) => {
        if (cellMode === 'edit' && detail.reason !== '') {
          const isEnter = event.key === 'Enter';
          const isEscape = event.key === 'Escape';

          if (isEnter || isEscape) {
            apiRef?.current.stopRowEditMode({
              id,
              field,
              ignoreModifications: isEscape,
            });

            event.stopPropagation();
            event.preventDefault();
          }
        }

        props.onCellKeyDown?.({ id, field, cellMode, ...rest }, event, detail);
      };
    }

    return props.onCellKeyDown;
  }, [apiRef, editableCellEvents, props.onCellKeyDown]);

  const onCellDoubleClick = useMemo<GridEventListener<'cellDoubleClick'> | undefined>(() => {
    if (editableCellEvents) {
      return ({ id, field: fieldToFocus, isEditable, cellMode, ...rest }, event, detail) => {
        if (cellMode === 'view' && isEditable) {
          apiRef?.current.startRowEditMode({ id, fieldToFocus });
          event.stopPropagation();
        }

        props.onCellDoubleClick?.(
          { id, field: fieldToFocus, isEditable, cellMode, ...rest },
          event,
          detail
        );
      };
    }

    return props.onCellDoubleClick;
  }, [apiRef, editableCellEvents, props.onCellDoubleClick]);

  const processRowUpdate = useMemo<DataGridProps<TRow>['processRowUpdate'] | undefined>(() => {
    if (editableCellEvents) {
      return async (newRow, oldRow, params) => {
        const getRowId = props.getRowId ?? ((row: TRow) => row.id);
        const index = ensureArray(props.rows).findIndex((r) => getRowId(r) === getRowId(oldRow));

        await onBeforeRowUpdate?.(index, getRowId(newRow));

        const result = (await rowSchema?.safeParseAsync(newRow)) ?? {
          success: true,
          data: newRow,
        };

        if (!result.success) {
          throw new RowSchemaError(index, result.error);
        }

        const updatedRow = await (props.processRowUpdate?.(result.data, oldRow, params) ??
          result.data);

        //! Wait a tick for MUI Datagrid to not mix up data updates with new row inserted
        setTimeout(() => {
          onAfterRowUpdate?.(index, updatedRow);
        });

        return updatedRow;
      };
    }

    return props.processRowUpdate;
  }, [editableCellEvents, onAfterRowUpdate, onBeforeRowUpdate, props, rowSchema]);

  const onProcessRowUpdateError = useMemo<
    DataGridProps<TRow>['onProcessRowUpdateError'] | undefined
  >(() => {
    if (editableCellEvents) {
      return (error) => {
        if (error instanceof RowSchemaError && onRowSchemaError) {
          onRowSchemaError?.(error.index, error.error);
        } else if (onProcessRowUpdateError) {
          onProcessRowUpdateError(error);
        } else {
          // Throw instead?
          console.error(error);
        }
      };
    }

    return props.onProcessRowUpdateError;
  }, [editableCellEvents, onRowSchemaError, props.onProcessRowUpdateError]);

  const GridContainer = useFragmentContainer ? Fragment : (gridContainer ?? AutoSizeBox);
  return (
    <GridContainer {...gridContainerProps}>
      <DataGridPro<TRow>
        apiRef={apiRef}
        initialState={initialState}
        editMode="row"
        pagination
        density={onDensityChange ? controlledDensity : undefined}
        onDensityChange={onDensityChange}
        disableColumnReorder
        disableColumnPinning
        disableColumnSelector
        keepNonExistentRowsSelected
        disableRowSelectionOnClick={!getRowNavigation && !props.onRowClick}
        onRowClick={
          getRowNavigation
            ? (params: GridRowParams<TRow>) => navigate(getRowNavigation(params))
            : undefined
        }
        {...props}
        sx={{
          borderColor: 'divider',
          [`&.${gridClasses.root} .${gridClasses.row}`]: {
            ...((props.onRowClick || getRowNavigation) && {
              cursor: 'pointer',
            }),
          },
          [`& .${gridClasses.cell}`]: {
            lineHeight: 'unset',
            display: 'flex',
            alignItems: 'center',
            borderBottomColor: 'divider',
          },
          [`& .${gridClasses.columnSeparator}`]: { color: 'divider' },
          [`& .${gridClasses.toolbarContainer}`]: { boxSizing: 'content-box' },
          [`& .${gridClasses.footerContainer}`]: { borderTopColor: 'divider' },
          [`& .${gridClasses.columnHeaders}`]: { borderBottomColor: 'divider' },
          [`& .${gridClasses['cell--editable']}:hover`]: {
            color: 'primary.main',
            textDecoration: 'underline',
          },
          ...(autoRowHeight && {
            [`&.${gridClasses['root--densityCompact']} .${gridClasses.cell}`]: { py: 0.15 },
            [`&.${gridClasses['root--densityStandard']} .${gridClasses.cell}`]: { py: 1 },
            [`&.${gridClasses['root--densityComfortable']} .${gridClasses.cell}`]: { py: 2 },
          }),
          ...sx,
        }}
        slots={{
          toolbar: GridSearchToolbar,
          footer: GridFooter,
          ...slots,
          ...(editableCellEvents && !disableOnClickAwaySave
            ? {
                cell: SaveOnClickAwayCell,
              }
            : null),
        }}
        slotProps={{
          ...slotProps,
          toolbar: {
            ...(disableFilter && { left: <></> }),
            ...slotProps?.toolbar,
          },
        }}
        rows={props.rows}
        columns={props.columns}
        getRowHeight={autoRowHeight ? getRowHeight : props.getRowHeight}
        onCellKeyDown={onCellKeyDown}
        onCellDoubleClick={onCellDoubleClick}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
      />
    </GridContainer>
  );
}
