import equal from 'fast-deep-equal';
import { DependencyList, useEffect, useRef } from 'react';
import { ensureArray } from 'system';

export function useValueChangePulse<T>(
  value: T,
  effect: (isFirstChange: boolean, current: T, previous?: T) => void | (() => void),
  deps?: DependencyList
): void {
  const previousValue = useRef<T>();
  const hasChangedBefore = useRef(false);

  const effectRef = useRef(effect);
  useEffect(() => {
    effectRef.current = effect;
  }, [effect]);

  useEffect(() => {
    const hasChanged = !equal(value, previousValue.current);

    if (hasChanged) {
      const isFirstChange = !hasChangedBefore.current;
      const cleanup = effectRef.current(isFirstChange, value, previousValue.current);

      hasChangedBefore.current = true;
      previousValue.current = value;

      if (typeof cleanup === 'function') {
        return cleanup;
      }
    }
  }, [value, ...ensureArray(deps)]);
}
