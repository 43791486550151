import { compact } from '@propra-system/registry';
import {
  OwnerListFieldsFragment,
  OwnersFilterInput,
  PageAccountOwnersQuery,
  usePageAccountOwnersQuery,
} from 'api';
import { intersection, orderBy, uniqBy } from 'lodash';
import { useAccount } from 'pages/account/hooks/useAccount';
import { useCallback, useMemo } from 'react';
import { ensureArray } from 'system';
import { useMeta } from './useMeta';

type UseServerOwnersProps = {
  first?: number;
  after?: string;
  filter?: OwnersFilterInput;
  skip?: boolean;
  onCompleted?: (owners: OwnerListFieldsFragment[]) => void;
};

export const useServerOwners = ({
  first = 25,
  after,
  filter,
  skip,
  onCompleted,
}: UseServerOwnersProps) => {
  const { accountName, accountId } = useAccount();

  const getOwners = useCallback(
    (data?: PageAccountOwnersQuery) =>
      compact([
        filter?.booksOwner && accountId && accountName
          ? { id: accountId, name: accountName }
          : null,
        ...ensureArray(data?.account?.pageOwners?.edges).map(({ node }) => node),
      ]),
    [accountId, accountName, filter?.booksOwner]
  );

  const { data, ...meta } = usePageAccountOwnersQuery({
    variables: { first, after, filter },
    skip,
    onCompleted: (completedData) => {
      onCompleted?.(getOwners(completedData));
    },
  });

  const { loading } = useMeta(meta);

  const owners = useMemo(() => getOwners(data), [data, getOwners]);

  const ownerOptions = useMemo(
    () =>
      orderBy(
        uniqBy(
          compact([
            filter?.booksOwner && accountId && accountName
              ? {
                  id: accountId,
                  text: accountName,
                  subText: 'Administrative',
                  priority: -1,
                }
              : null,
            ...owners.map(({ id, name: ownerName, propertyIds, unitIds, email, ...rest }) => {
              const [ownedPropertyId] = intersection(propertyIds, filter?.propertyIds);
              const ownsProperty = !!ownedPropertyId;
              const ownedUnitId = unitIds?.[0];
              const propertyText = ownsProperty
                ? rest.propertyHeaders?.find(({ id: propertyId }) => propertyId === ownedPropertyId)
                    ?.name
                : ownedUnitId
                  ? rest.unitHeaders?.find(({ id: unitId }) => unitId === ownedUnitId)?.propertyName
                  : undefined;

              return {
                id,
                text: ownerName,
                propertyText,

                ...(filter?.booksOwner && filter?.propertyIds
                  ? {
                      subText: ownsProperty ? 'Property Owner' : ownedUnitId ? 'Unit Owner' : '',
                      priority: ownsProperty ? 1 : 0,
                    }
                  : { subText: email, priority: 0 }),
              };
            }),
          ]),
          'id'
        ),
        ['priority'],
        ['desc']
      ),
    [filter?.booksOwner, filter?.propertyIds, accountId, accountName, owners]
  );

  return {
    owners,
    ownerOptions,
    loading,
  };
};
