import { CountryCode, useGetCountriesQuery } from 'api';
import { get, uniqBy } from 'lodash';
import { useMemo } from 'react';
import { ensureArray } from 'system';
import { Place } from './useGoogleAutocompletePredictions';
import { useMeta } from './useMeta';

export const useCountries = () => {
  const { data, ...meta } = useGetCountriesQuery();

  const { loading } = useMeta(meta);

  const countries = useMemo(
    () => [...ensureArray(data?.countries)].sort((a, b) => a.name.localeCompare(b.name)),
    [data?.countries]
  );

  const getProvinceLabel = (country?: string) =>
    country ? (countries.find((c) => c.id === country)?.provinceLabel ?? 'Province') : 'Province';

  const getPostalLabel = (country?: string) =>
    country
      ? (countries.find((c) => c.id === country)?.postalLabel ?? 'Postal Code')
      : 'Postal Code';

  const getTimezone = (country: string, province = '') => {
    const countryData = countries?.find((c) => c.id === country);
    const provinceData = countryData?.provinces?.find((p) => p.code === province);
    return provinceData?.timezone ?? countryData?.timezone;
  };

  const placeToAddress = (place?: Place, country: string = CountryCode.Ca) => {
    const timezone = getTimezone(country, place?.province);
    return {
      ...(place?.streetName && { street: place.streetName }),
      ...(place?.city && { city: place.city }),
      ...(place?.postalCode && { postal: place.postalCode }),
      ...(place?.lat && { lat: place.lat }),
      ...(place?.lng && { lng: place.lng }),
      ...(place?.province && { province: place.province }),
      ...(timezone ? { timezone } : {}),
      ...(country && { country }),
    };
  };

  const provinceName = (province: string, country: string = CountryCode.Ca) =>
    countries.find((c) => c.id === country)?.provinces?.find((p) => p.code === province)?.name;

  const getProvincesForCountry = (country?: string) =>
    ensureArray(
      countries
        ?.find((c) => c.id === country)
        ?.provinces?.map((p) => ({
          label: p.name,
          value: p.code,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label))
    );

  const timezoneOptionsFor = (country?: string) => {
    const countryData = countries?.find((c) => c.id === country);
    const countryTimezone = countryData?.timezone ?? '';
    return uniqBy(
      ensureArray(get(countryData, 'provinces', []))?.map((p) => ({
        label: get(p, 'timezone', countryTimezone),
        value: get(p, 'timezone', countryTimezone),
      })),
      'value'
    );
  };

  const countryName = (country: string = CountryCode.Ca) =>
    countries.find((c) => c.id === country)?.name;

  return {
    countries,
    loading,
    getProvinceLabel,
    getPostalLabel,
    placeToAddress,
    getTimezone,
    provinceName,
    getProvincesForCountry,
    timezoneOptionsFor,
    countryName,
  };
};
