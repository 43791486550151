import { channelBy } from '@propra-system/util/channelBy';
import { PersistentState, usePersistentState } from 'hooks/usePersistentState';
import { uniq } from 'lodash';
import { ReactNode, SetStateAction, createContext, useCallback, useContext, useMemo } from 'react';
import { ifDifferent } from 'system';

export type PropertyOwnerContextType = {
  ownerPropertyId: string | string[] | undefined;
  ownerId: string | string[] | undefined;
  propertyId: string | string[] | undefined;
  setPropertyOwner: (
    setter: SetStateAction<
      string | string[] | Pick<PropertyOwnerContextType, 'ownerId' | 'propertyId'> | undefined
    >
  ) => void;
};

const initialState: PropertyOwnerContextType = {
  ownerPropertyId: undefined,
  ownerId: undefined,
  propertyId: undefined,
  setPropertyOwner: () => undefined,
};

const PropertyOwnerContext = createContext<PropertyOwnerContextType>(initialState);

export const usePropertyOwnerContext = () => {
  const context = useContext(PropertyOwnerContext);
  if (!context) {
    throw new Error('usePropertyOwnerContext must be used within an PropertyOwnerProvider');
  }

  return context;
};

export const PropertyOwnerProvider = ({
  children,
  ownerId: initialOwnerId,
  propertyId: initialPropertyId,
  propertyOwnerId: initialPropertyOwnerId,
  state = PersistentState.propertyOwner,
  stateOptions,
}: {
  children: ReactNode;
  propertyId?: string | string[];
  ownerId?: string | string[];
  propertyOwnerId?: string | string[];
  state?: PersistentState;
  stateOptions?: Parameters<typeof usePersistentState>[2];
}) => {
  const [value, _setPropertyOwner] = usePersistentState<
    string | string[] | Pick<PropertyOwnerContextType, 'ownerId' | 'propertyId'> | undefined
  >(
    state,
    initialPropertyOwnerId ?? { ownerId: initialOwnerId, propertyId: initialPropertyId },
    stateOptions
  );

  const { ownerId, propertyId, ownerPropertyId } = useMemo(() => {
    if (typeof value === 'string') {
      const [oid, pid] = value.split('#');
      return { ownerId: oid, propertyId: pid, ownerPropertyId: value };
    } else if (Array.isArray(value)) {
      const { oids, pids } = channelBy(value, {
        oids: (v) => v.split('#')[0],
        pids: (v) => v.split('#')[1],
      });

      return {
        ownerId: Array.from(oids),
        propertyId: Array.from(pids),
        ownerPropertyId: value,
      };
    }

    return value
      ? { ...value, ownerPropertyId: undefined }
      : { ownerId: undefined, propertyId: undefined, ownerPropertyId: undefined };
  }, [value]);

  const setPropertyOwner = useCallback(
    (setter: SetStateAction<typeof value>) => {
      const newState = typeof setter === 'function' ? setter(value) : setter;
      const uniqState =
        newState &&
        (Array.isArray(newState)
          ? uniq(newState)
          : typeof newState === 'string'
            ? newState
            : {
                ownerId: Array.isArray(newState.ownerId)
                  ? uniq(newState.ownerId)
                  : newState.ownerId,
                propertyId: Array.isArray(newState.propertyId)
                  ? uniq(newState.propertyId)
                  : newState.propertyId,
              });

      _setPropertyOwner(ifDifferent(uniqState));
    },
    [_setPropertyOwner, value]
  );

  return (
    <PropertyOwnerContext.Provider
      value={{
        ownerId,
        propertyId,
        ownerPropertyId,
        setPropertyOwner,
      }}
    >
      {children}
    </PropertyOwnerContext.Provider>
  );
};
