import { RelayPageInfo } from 'api';
import { useCallback, useRef } from 'react';

type Merge<A, B> = {
  [K in keyof (A & B)]: K extends keyof A ? A[K] : K extends keyof B ? B[K] : never;
};

export type PageInfo = Merge<RelayPageInfo, { runningTotalRows?: number; totalRows?: number }>;

export const usePageMapping = () => {
  const pageMappingRef = useRef<{ [page: number]: PageInfo }>({});

  const getEndCursor = useCallback(
    (pageNumber: number) => pageMappingRef.current[pageNumber - 1]?.endCursor,
    []
  );

  const putPageInfo = useCallback(
    (pageNumber: number, pageInfo?: PageInfo, runningTotalRows?: number) => {
      if (pageInfo) {
        pageMappingRef.current[pageNumber] = {
          ...pageInfo,
          ...pageMappingRef.current[pageNumber],
          ...(runningTotalRows && { runningTotalRows }),
        };
      }
    },
    []
  );

  const putLastPageInfo = useCallback(
    (pageNumber: number, totalRows: number, endCursor?: string) => {
      pageMappingRef.current[pageNumber] = {
        endCursor,
        totalRows,
        hasNextPage: false,
      };

      for (let i = 0; i < pageNumber; i++) {
        pageMappingRef.current[i] = {
          ...pageMappingRef.current[i],
          totalRows,
          runningTotalRows: undefined,
        };
      }
    },
    []
  );

  const resetMapping = useCallback(() => {
    pageMappingRef.current = {};
  }, []);

  const getPageInfo = useCallback(
    (pageNumber: number): PageInfo | undefined => pageMappingRef.current[pageNumber],
    []
  );

  return { getEndCursor, putPageInfo, resetMapping, getPageInfo, putLastPageInfo };
};
