import { Cache } from '@aws-amplify/cache';
import { simpleHash } from '@propra-system/util/simpleHash';
import { ManagerRole } from 'api';
import { constant } from 'lodash';
import { ensureArray } from 'system';
import { AuthAction, AuthActions, AuthState, CognitoResponse, MappedCognitoGroup } from './types';

const localUserDetails: CognitoResponse = Cache.getItem('currentUser');

const groupMapping = {
  [MappedCognitoGroup.ACCOUNT_ADMIN]: ManagerRole.Admin,
};

const toApiRole = (group: MappedCognitoGroup) =>
  groupMapping[group] ?? (group as unknown as ManagerRole);

export const getRolesFrom = (response: CognitoResponse) =>
  ensureArray(response.signInUserSession.idToken.payload['cognito:groups']).map(toApiRole);

const token = localUserDetails ? localUserDetails.signInUserSession.idToken.jwtToken : '';

const includesAnyRoleWithRoles =
  (roles: ManagerRole[]) =>
  (...restrictions: ManagerRole[]) =>
    roles && restrictions.some((r) => roles.includes(r));

const getRolesState = (userDetails?: CognitoResponse) => {
  const roles = userDetails ? getRolesFrom(userDetails) : [];
  const includesAnyRole = includesAnyRoleWithRoles(roles);

  const isAdmin = includesAnyRole(ManagerRole.Admin);
  const isBooksAdmin = includesAnyRole(ManagerRole.BooksAdmin);
  const isBooksAdvanced = includesAnyRole(ManagerRole.BooksAdmin, ManagerRole.BooksAdvanced);
  const isBooksUser = includesAnyRole(
    ManagerRole.BooksAdmin,
    ManagerRole.BooksAdvanced,
    ManagerRole.BooksUser
  );
  const isBooksReporter = includesAnyRole(
    ManagerRole.BooksAdmin,
    ManagerRole.BooksAdvanced,
    ManagerRole.BooksUser,
    ManagerRole.BooksReporter
  );

  return {
    roles,
    includesAnyRole,
    isAdmin,
    isBooksAdmin,
    isBooksAdvanced,
    isBooksUser,
    isBooksReporter,
  };
};

export const initialState: AuthState = {
  userDetails: localUserDetails,
  token,
  loading: false,
  mustCreatePassword: false,
  accountId: localUserDetails?.signInUserSession.idToken.payload['custom:account'] ?? '',
  tier: localUserDetails?.signInUserSession.idToken.payload['custom:tier'] ?? 1,
  managerId: localUserDetails?.username,
  isAuthenticated: Boolean(token),
  ...getRolesState(localUserDetails),
  withAccountHash: <TStr extends string>(s: TStr) =>
    (initialState.accountId ? `${simpleHash(initialState.accountId)}#${s}` : s) as TStr,
};

export const authReducer: React.Reducer<AuthState, AuthAction> = (
  state: AuthState,
  action: AuthAction
) => {
  switch (action.type) {
    case AuthActions.REQUEST_LOGIN:
      return {
        ...state,
        loading: true,
        errorMessage: undefined,
      };
    case AuthActions.NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        userDetails: action.payload,
        mustCreatePassword: true,
      };
    case AuthActions.PASSWORD_CREATE_SUCCESS:
      return {
        ...state,
        mustCreatePassword: false,
      };
    case AuthActions.LOGIN_SUCCESS:
      return {
        ...state,
        managerId: action.payload?.signInUserSession.idToken.payload.sub,
        token: action.payload.signInUserSession.idToken.jwtToken,
        isAuthenticated: true,
        loading: false,
        accountId: action.payload.signInUserSession.idToken.payload['custom:account'] ?? '',
        tier: action.payload.signInUserSession.idToken.payload['custom:tier'] ?? 1,
        ...getRolesState(action.payload),
        withAccountHash: <TStr extends string>(s: TStr) => {
          const accountId =
            action.payload.signInUserSession.idToken.payload['custom:account'] ?? '';

          return (accountId ? `${simpleHash(accountId)}#${s}` : s) as TStr;
        },
      };
    case AuthActions.UPDATE_USER:
      return {
        ...state,
        token: action.payload.idToken,
      };
    case AuthActions.REQUEST_LOGOUT:
      return {
        ...state,
        roles: undefined,
        includesAnyRole: constant(false),
        loading: false,
        mustCreatePassword: false,
        isAuthenticated: false,
        accountId: '',
        tier: 0,
        managerId: '',
      };
    case AuthActions.LOGOUT_SUCCESS:
      return {
        ...state,
        token: '',
      };
    case AuthActions.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.error,
      };
    case AuthActions.CLEAR_LOGIN_ERROR:
      return {
        ...state,
        errorMessage: undefined,
      };

    default:
      throw new Error(`Unhandled action: ${JSON.stringify(action)}`);
  }
};
