/**
 * Channels a list of items into a set of sets, where each set is created by applying a transform to each item.
 * Only non-null, non-undefined values are added to the sets.
 *
 * @param items - The list of items to channel.
 * @param transforms - The transforms to apply to each item.
 * @returns An object where each key is a transform and the value is a set of the results of the transform.
 * @example
 * const items = [
 *   { id: 1, name: 'Alice', age: 25 },
 *   { id: 2, name: 'Bob', age: 30 },
 *   { id: 3, name: 'Charlie', age: 35 }
 * ];
 * const transforms = {
 *   name: (item) => item.name,
 *   age: (item) => item.age
 * };
 * assert.deepEqual({
 *   name: new Set(['Alice', 'Bob', 'Charlie']),
 *   age: new Set([25, 30, 35])
 * }, channelBy(items, transforms));
 */
export const channelBy = <T, TTransforms extends Record<string, (item: T) => unknown>>(
  items: T[] | undefined | null,
  transforms: TTransforms
): { [K in keyof TTransforms]: Set<NonNullable<ReturnType<TTransforms[K]>>> } => {
  return (items ?? []).reduce(
    (acc, item) => {
      Object.entries(transforms).forEach(([key, transform]) => {
        const k = key as keyof TTransforms;
        const value = transform(item) as ReturnType<TTransforms[typeof k]>;

        if (value !== null && value !== undefined) {
          acc[k].add(value);
        }
      });
      return acc;
    },
    Object.fromEntries(Object.keys(transforms).map((k) => [k, new Set()])) as {
      [K in keyof TTransforms]: Set<NonNullable<ReturnType<TTransforms[K]>>>;
    }
  );
};
