import { ServerSupplierFieldsFragment } from 'api';
import { AutocompleteField, AutocompleteMultiField } from 'components';
import { useDebouncedValue } from 'hooks/useDebounce';
import { useServerSuppliers } from 'hooks/useServerSuppliers';
import { useState } from 'react';
import { Option, shallowOmit, tuple } from 'system';

type MultipleProps = {
  selectionMode: 'multiple';
  value?: string[];
  defaultValue?: string[];
  onChange?: (suppliers?: ServerSupplierFieldsFragment[]) => void;
  filter?: Partial<Parameters<typeof useServerSuppliers>[0]['filter']>;
} & Omit<
  Partial<Parameters<typeof AutocompleteMultiField<string>>[0]>,
  'options' | 'loading' | 'onInputChange' | 'onChange' | 'value' | 'defaultValue'
>;

type SingleProps = {
  selectionMode?: 'single';
  value?: string | Option<string>;
  defaultValue?: string | Option<string>;
  onChange?: (supplier?: ServerSupplierFieldsFragment) => void;
  filter?: Partial<Parameters<typeof useServerSuppliers>[0]['filter']>;
} & Omit<
  Partial<Parameters<typeof AutocompleteField<string>>[0]>,
  'options' | 'loading' | 'onInputChange' | 'onChange' | 'value' | 'defaultValue'
>;

const matchSelectionMode = (props: SupplierAutocompleteFieldProps) =>
  props.selectionMode === 'multiple'
    ? tuple(props.selectionMode, shallowOmit(props, ['selectionMode']))
    : tuple(props.selectionMode, shallowOmit(props, ['selectionMode']));

export type SupplierAutocompleteFieldProps = MultipleProps | SingleProps;

export const SupplierAutocompleteField = ({
  filter,
  ...fieldProps
}: SupplierAutocompleteFieldProps) => {
  const [searchText, setSearchText] = useState('');
  const [debouncedSearch, debouncing] = useDebouncedValue(searchText, { delay: 250 });
  const { suppliers, supplierOptions, loading } = useServerSuppliers({
    filter: { ...filter, search: debouncedSearch },
  });

  const [selectionMode, props] = matchSelectionMode(fieldProps);

  return selectionMode === 'multiple' ? (
    <AutocompleteMultiField
      variant="filled"
      options={supplierOptions}
      loading={loading || debouncing}
      {...props}
      onInputChange={(_, value) => {
        setSearchText(value);
      }}
      onSelected={(newValue) => {
        props.onChange?.(suppliers.filter(({ id }) => newValue.includes(id)));
      }}
    />
  ) : (
    <AutocompleteField
      variant="filled"
      options={supplierOptions}
      hideIcons={true}
      loading={loading || debouncing}
      {...props}
      onInputChange={(_, value) => {
        setSearchText(value);
      }}
      onChange={(_, newValue) => {
        const newId = typeof newValue === 'string' ? newValue : newValue?.id;
        props.onChange?.(suppliers.find(({ id }) => id === newId));
      }}
    />
  );
};
