import {
  API,
  BalanceType,
  ClearableFieldsFragmentDoc,
  JournalEntryFieldsFragment,
  JournalEntryLineInput,
  NsfOptions,
  useAddClearableMutation,
  useAddJournalEntryMutation,
  useNotifyPayeePaymentReturnedMutation,
} from 'api';
import { useMeta } from 'hooks/useMeta';
import { useModalControl } from 'hooks/useModalControl';
import { compact } from 'lodash';
import { useAccount } from 'pages/account/hooks/useAccount';
import { concurrent, concurrentMutations, stripNonData } from 'system';
import { GlobalMapping } from '../utils';
import { useGlMapping } from './useGlMapping';

export type NSFFormFields = { posted: string; fee: number; notifyPaymentReturned: boolean };

export const useNSF = () => {
  const { account } = useAccount();
  const { mappedGlId } = useGlMapping();
  const [showNSFModal, hideNSFModal, nsfModalOpen, jeToMarkNSF] =
    useModalControl<JournalEntryFieldsFragment>();
  const [notifyPaye, metaNotify] = useNotifyPayeePaymentReturnedMutation();
  const [addJournalEntryMutation, metaJournal] = useAddJournalEntryMutation();
  const [addClearableMutation, metaClearable] = useAddClearableMutation();

  const { loading } = useMeta(metaJournal, metaClearable, metaNotify);

  const addNSF = async (
    jeForNSF: JournalEntryFieldsFragment,
    { posted, fee: feeAmount, notifyPaymentReturned }: NSFFormFields
  ) => {
    const { ownerId, propertyId, unitId, clearable } = jeForNSF;
    const otherRevenue = mappedGlId(GlobalMapping.otherRevenue);
    const accountsReceivable = mappedGlId(GlobalMapping.accountsReceivable);
    const accountsPayable = mappedGlId(GlobalMapping.accountsPayable);
    const bankCharges = mappedGlId(GlobalMapping.bankCharges);
    const operatingBank = mappedGlId(GlobalMapping.operatingBank);

    const nsfChargeTo = account?.books?.nsfChargeTo ?? NsfOptions.Property;

    const ownerNsf = nsfChargeTo === NsfOptions.AdminAccount && account ? account.id : ownerId;
    const propertyNsf =
      nsfChargeTo === NsfOptions.AdminAccount && account ? account.id : propertyId;

    const entriesToCreate: JournalEntryLineInput[][] = compact([
      jeForNSF.glId === accountsPayable && bankCharges && operatingBank && account
        ? [
            {
              ownerId: ownerNsf,
              propertyId: propertyNsf,
              unitId,
              amount: feeAmount,
              description: `NSF fee ${jeForNSF.description ?? ''} ${jeForNSF.posted}`,
              glId: bankCharges,
              payee: jeForNSF.payee,
              payeeId: jeForNSF.payeeId,
            },
            {
              ownerId: ownerNsf,
              propertyId: propertyNsf,
              unitId,
              amount: -feeAmount,
              description: `NSF fee ${jeForNSF.description ?? ''} ${jeForNSF.posted}`,
              glId: operatingBank,
              payee: jeForNSF.payee,
              payeeId: jeForNSF.payeeId,
            },
          ]
        : undefined,
      [
        ...jeForNSF.lines.map(({ __typename, id: _id, ...line }) => ({
          ...stripNonData(line),
          ownerId: ownerNsf,
          propertyId: propertyNsf,
          amount: -line.amount,
          chargeback: true,
          ref: 'NSF',
          description: `NSF for '${line.description ?? 'entry'}'`,
          payee: jeForNSF.payee,
          payeeId: jeForNSF.payeeId,
        })),
      ],
    ]);

    try {
      await concurrent(
        concurrentMutations,
        entriesToCreate.map(
          (lines) => () =>
            addJournalEntryMutation({
              variables: { input: { posted, lines } },
              update(cache, { data }) {
                if (data?.addJournalEntry?.success) {
                  const id = cache.identify({ id: jeForNSF.clearableId, __typename: 'Clearable' });
                  cache.evict({ id });
                  cache.gc();
                }
              },
            })
        )
      );

      if (notifyPaymentReturned && jeForNSF.payeeId && jeForNSF.payee) {
        await notifyPaye({
          variables: {
            input: {
              payeeId: jeForNSF.payeeId,
              payeeType: jeForNSF.payee,
              amount: jeForNSF.amount,
              date: posted,
              description: clearable?.description ?? '',
              fee: feeAmount,
            },
          },
        });
      }

      if (feeAmount > 0 && clearable) {
        const arNsf =
          clearable.balanceType === BalanceType.Debit && accountsReceivable && otherRevenue
            ? {
                otherGlId: otherRevenue,
                clearableGlId: accountsReceivable,
              }
            : null;

        const apNsf =
          clearable.balanceType === BalanceType.Credit && accountsPayable && bankCharges
            ? {
                otherGlId: bankCharges,
                clearableGlId: accountsPayable,
              }
            : null;

        const { otherGlId, clearableGlId } = arNsf ?? apNsf ?? {};

        if (otherGlId && clearableGlId) {
          await addClearableMutation({
            variables: {
              input: {
                posted,
                due: posted,
                payee: clearable.payee,
                payeeId: clearable.payeeId,
                lines: [
                  {
                    ownerId: ownerNsf,
                    propertyId: propertyNsf,
                    unitId,
                    amount: feeAmount,
                    description: `NSF fee on ${jeForNSF.description ?? ''} ${jeForNSF.posted}`,
                    glId: clearableGlId,
                    payee: clearable.payee,
                    payeeId: clearable.payeeId,
                  },
                  {
                    ownerId: ownerNsf,
                    propertyId: propertyNsf,
                    unitId,
                    amount: feeAmount,
                    description: `NSF fee on ${jeForNSF.description ?? ''} ${jeForNSF.posted}`,
                    glId: otherGlId,
                    payee: clearable.payee,
                    payeeId: clearable.payeeId,
                  },
                ],
              },
            },
            update(cache, { data: mutationResponse }) {
              const id = cache.identify({ id: account?.id, __typename: 'Account' });
              if (
                mutationResponse?.addClearable?.success &&
                mutationResponse.addClearable.clearable
              ) {
                const ref = cache.writeFragment({
                  data: mutationResponse.addClearable.clearable,
                  fragment: ClearableFieldsFragmentDoc,
                  fragmentName: API.Fragment.ClearableFields,
                });
                cache.modify({
                  id,
                  fields: {
                    listClearables: (existing = { items: [] }) => ({
                      ...existing,
                      items: [...existing.items, ref],
                    }),
                    payeeClearables: (existing = { items: [] }, { storeFieldName }) => {
                      const payeeId = mutationResponse.addClearable.clearable?.payeeId;
                      return payeeId && storeFieldName.includes(payeeId)
                        ? {
                            ...existing,
                            items: [...existing.items, ref],
                          }
                        : existing;
                    },
                  },
                });
              }
            },
          });
        }
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return {
    showNSFModal,
    hideNSFModal,
    nsfModalOpen,
    jeToMarkNSF,
    addNSF,
    loading,
  };
};
