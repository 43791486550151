import { Button, Grid2, Skeleton, Stack, TextField } from '@mui/material';
import { Address, CountryCode, useGetAccountQuery } from 'api';
import { DropdownField, GooglePlacesAutocomplete } from 'components';
import { GoogleMap } from 'components/GoogleMap';
import { useCountries } from 'hooks/useCountries';
import { useMeta } from 'hooks/useMeta';
import { useEffect, useMemo, useState } from 'react';
import { FieldErrors } from 'react-hook-form';

type FullAddress = Omit<Address, '__typename'> & {
  country?: string;
  timezone?: string;
};

export type AddressFieldsProps = {
  value?: FullAddress;
  errors?: FieldErrors<FullAddress>;
  onAddressChanged: (newAddr: FullAddress) => void;
  minimal?: boolean;
  showMap?: boolean;
  excludes?: (keyof FullAddress)[];
};

export const AddressFields = ({
  value,
  errors,
  minimal,
  showMap,
  onAddressChanged,
  excludes = ['suite'],
}: AddressFieldsProps) => {
  const { data, ...meta } = useGetAccountQuery();
  useMeta(meta);
  const { address } = data?.account ?? {};
  const accountCountry = address?.country ?? CountryCode.Ca;
  const country = value?.country ?? CountryCode.Ca;
  const [showCountryPicker, setShowCountryPicker] = useState(country !== accountCountry);

  const {
    countries,
    getProvinceLabel,
    getPostalLabel,
    placeToAddress,
    getTimezone,
    getProvincesForCountry,
    timezoneOptionsFor,
    countryName,
    loading,
  } = useCountries();

  useEffect(() => {
    setShowCountryPicker(country !== accountCountry);
  }, [country, accountCountry]);

  const handleAddressChanged = (newAddr: Partial<FullAddress>) => {
    const updatedAddr = { ...value, ...newAddr };
    const newCountry = updatedAddr.country ? updatedAddr.country : CountryCode.Ca;
    const timezone = getTimezone(newCountry, updatedAddr.province);

    onAddressChanged({
      ...updatedAddr,
      ...(timezone ? { timezone } : {}),
      ...(newCountry && { country: newCountry }),
    });
  };

  const provinces = useMemo(() => getProvincesForCountry(country), [country, countries]);

  return loading ? (
    <Skeleton variant="rectangular" height={100} />
  ) : (
    <Grid2 container direction="row" spacing={1}>
      <Grid2 size={{ xs: 12, sm: 'grow' }}>
        <Stack spacing={2}>
          {!excludes.includes('country') && showCountryPicker && (
            <DropdownField
              label="Country"
              fullWidth
              data={countries.map((c) => ({
                label: c.name,
                value: c.id,
              }))}
              value={country}
              onChange={(event) => onAddressChanged({ country: String(event.target.value) })}
              error={errors?.country}
            />
          )}

          {!excludes.includes('street') && (
            <GooglePlacesAutocomplete
              disableClearable
              country={country}
              initialValue={value?.street}
              onChange={(place) => handleAddressChanged(placeToAddress(place, country))}
              renderInput={(params) => {
                const paramsWithoutAdornment = {
                  ...params,
                  InputProps: {
                    ...params.InputProps,
                    endAdornment: null,
                  },
                };

                return (
                  <TextField
                    {...paramsWithoutAdornment}
                    variant={'filled'}
                    value={value?.street ?? ''}
                    onChange={(event) =>
                      handleAddressChanged({ street: String(event.target.value) })
                    }
                    label="Street"
                    error={Boolean(errors?.street)}
                    helperText={errors?.street?.message}
                  />
                );
              }}
            />
          )}

          {!excludes.includes('suite') && (
            <TextField
              name="suite"
              label="Suite/Unit"
              variant={'filled'}
              value={value?.suite ?? ''}
              onChange={(event) => handleAddressChanged({ suite: String(event.target.value) })}
              error={Boolean(errors?.suite)}
              helperText={errors?.suite?.message}
            />
          )}

          <TextField
            name="city"
            label="City"
            variant={'filled'}
            value={value?.city ?? ''}
            onChange={(event) => handleAddressChanged({ city: String(event.target.value) })}
            error={Boolean(errors?.city)}
            helperText={
              errors?.city?.message ??
              (!excludes.includes('country') && !showCountryPicker && (
                <Button size="small" variant="text" onClick={() => setShowCountryPicker(true)}>
                  Not in {countryName(accountCountry)}?
                </Button>
              ))
            }
          />

          <DropdownField
            name="province"
            label={getProvinceLabel(country)}
            fullWidth
            data={provinces}
            value={value?.province ?? ''}
            onChange={(event) => handleAddressChanged({ province: String(event.target.value) })}
            error={errors?.province}
          />

          {!excludes.includes('postal') && (
            <TextField
              label={getPostalLabel(country)}
              variant={'filled'}
              value={value?.postal ?? ''}
              onChange={(event) => handleAddressChanged({ postal: String(event.target.value) })}
              error={Boolean(errors?.postal)}
              helperText={errors?.postal?.message}
            />
          )}

          {!excludes.includes('timezone') && (
            <DropdownField
              name="timezone"
              label="Timezone"
              fullWidth
              data={timezoneOptionsFor(country)}
              value={value?.timezone ?? ''}
              onChange={(event) => handleAddressChanged({ timezone: String(event.target.value) })}
              error={errors?.timezone}
            />
          )}
        </Stack>
      </Grid2>

      {!minimal && showMap && (
        <Grid2 size={{ xs: 12, sm: 'grow' }}>
          <GoogleMap
            height="100%"
            draggableMarker
            sx={{ mt: 2, pb: 2 }}
            lat={value?.lat}
            lng={value?.lng}
            onChange={(pos) => handleAddressChanged({ lat: pos.lat(), lng: pos.lng() })}
          />
        </Grid2>
      )}
    </Grid2>
  );
};
