import { QueryHookOptions, QueryResult } from '@apollo/client';
import { useTimeout } from 'hooks/useTimeout';
import { Duration } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';

type PollQueryUntilOptions<
  TData extends Record<string, unknown>,
  TVariables extends Record<string, unknown>,
> = QueryHookOptions<TData, TVariables> & {
  pollInterval?: number;
  shouldPoll: (data?: TData) => boolean;
} & (
    | { timeout?: undefined; pollUntil: (data?: TData) => boolean }
    | { timeout: Duration; pollUntil?: undefined }
  );

export const usePollQueryUntil = <
  TData extends Record<string, unknown>,
  TVariables extends Record<string, unknown>,
>(
  useListData: (options: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>,
  {
    pollInterval: interval = 5000,
    shouldPoll,
    ...options
  }: PollQueryUntilOptions<TData, TVariables>
) => {
  const { data, startPolling, stopPolling, ...meta } = useListData({ ...options });

  const timeoutSeconds = useMemo(() => options.timeout?.toMillis(), [options.timeout]);
  const [timedOut, setTimedOut] = useState(false);
  useTimeout(() => setTimedOut(true), { delay: timeoutSeconds, leading: false });

  const untilFn = useMemo(() => options.pollUntil, [options]);
  const shouldPollFn = useCallback(shouldPoll, [shouldPoll]);

  const stop = untilFn?.(data) || timedOut;
  useEffect(() => {
    if (shouldPollFn(data) && !stop) {
      startPolling(interval);
    } else {
      stopPolling();
    }
  }, [data, interval, shouldPollFn, startPolling, stopPolling, stop]);

  return [stop, { data, ...meta }] as const;
};
