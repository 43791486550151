import { TextFieldProps } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { DateTime, Zone } from 'luxon';
import { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import { DateLike, parseDates, parseDatesWithTz } from 'system';

export type DateFieldProps = {
  value?: DateLike | null;
  minDate?: DateLike;
  maxDate?: DateLike;
  disabled?: boolean;
  disableFuture?: boolean;
  timezone?: Zone | string;
  onChange?: (newDate?: string) => void;
  error?: FieldError;
} & Omit<
  TextFieldProps & Partial<Parameters<typeof DatePicker>[0]>,
  'value' | 'error' | 'onChange' | 'defaultValue'
>;

export const DateField = forwardRef<HTMLDivElement, DateFieldProps>(function DateField(
  {
    value,
    minDate,
    maxDate,
    disabled,
    disableFuture,
    onChange,
    error,
    timezone,
    ...componentProps
  }: DateFieldProps,
  ref
) {
  const [fieldValue, minDateTime, maxDateTime] = timezone
    ? parseDatesWithTz(timezone, value, minDate, maxDate)
    : parseDates(value, minDate, maxDate);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker
        {...componentProps}
        value={fieldValue}
        format="yyyy-MM-dd"
        {...{
          ref,
          disabled,
          disableFuture,
          minDate: minDateTime.isValid ? minDateTime : undefined,
          maxDate: maxDateTime.isValid ? maxDateTime : undefined,
        }}
        onChange={(date) => {
          date instanceof DateTime && onChange?.(date.isValid ? date.toISODate() : '');
        }}
        slotProps={{
          textField: {
            type: 'text',
            ...componentProps,
            ...(error?.message && {
              error: true,
              helperText: error.message,
            }),
          },
        }}
      />
    </LocalizationProvider>
  );
});
