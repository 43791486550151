import {
  AccountType,
  AddJournalEntryInput,
  API,
  BalanceType,
  GstInfo,
  PayeeType,
  RecurrenceFieldsFragmentDoc,
  useAddJournalEntryMutation,
  useGetJournalEntryQuery,
} from 'api';
import { useAuth } from 'context';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useMeta } from 'hooks/useMeta';
import { useNotification } from 'hooks/useNotification';
import { UploadError, UploadSuccess } from 'hooks/useUploadFiles';
import { DateTime } from 'luxon';
import { GLCategory } from 'pages/accounting/categories';
import { useMemo } from 'react';
import { ensureArray, safeSum } from 'system';
import { ExpenseFormFields } from '../tasks/EnterExpense/schema';
import { useGlAccounts } from './useGlAccounts';
import { useGlMapping } from './useGlMapping';

export const expenseOffsetCategories: GLCategory[] = ['bank', 'creditCard', 'bankCapitalReserve'];

export const useEnterExpense = (sourceJournalId = '') => {
  const { sendNotification } = useNotification();
  const { currentUser } = useCurrentUser();
  const { mappedGlId } = useGlMapping();
  const { accountId } = useAuth();
  const { findGlAccount } = useGlAccounts();
  const [addJournalEntryMutation, addJournalEntryMeta] = useAddJournalEntryMutation();
  const { data, ...journalEntryMeta } = useGetJournalEntryQuery({
    variables: { id: sourceJournalId },
    skip: !sourceJournalId,
  });
  useMeta(journalEntryMeta, addJournalEntryMeta);
  const gstId = mappedGlId('gstHstPayable');

  const enterExpense = async (
    values: ExpenseFormFields,
    filesToUpload: File[],
    uploadFiles: ({ id }: { id: string }) => Promise<{
      successfulUploads: UploadSuccess[];
      failedUploads: UploadError[];
    }>
  ) => {
    const { balanceType: rootBalanceType } = { ...findGlAccount(values.glId) };
    const total = values.lines.reduce(
      (result, line) => safeSum(result, line.billAmounts),
      values.applyGstHst ? values.gst : 0
    );

    if (values.applyGstHst && !gstId) {
      sendNotification('GST/HST account mapping not found, check Financial Settings', 'error');
      return;
    }

    const baseLine = {
      ownerId: values.ownerId,
      propertyId: values.propertyId,
      ref: values.ref,
      payeeId: values.payeeId,
      payee: values.payee,
      description: values.lines[0].description,
    };

    const firstUnitId = values.lines[0].unitId;
    const jeInput: AddJournalEntryInput = {
      posted: values.posted,
      lines: [
        {
          ...baseLine,
          ...(firstUnitId && { unitId: firstUnitId }),
          glId: values.glId,
          amount: rootBalanceType === BalanceType.Credit ? total : -total,
        },
        ...(values.applyGstHst && gstId
          ? [
              {
                ...baseLine,
                ...(firstUnitId && { unitId: firstUnitId }),
                glId: gstId,
                amount: -values.gst,
                gstInfo: GstInfo.Line106,
              },
            ]
          : []),
        ...values.lines.map(({ glId, unitId, description, billAmounts }) => {
          const { balanceType: lineBalanceType } = { ...findGlAccount(glId) };

          const amount = lineBalanceType === BalanceType.Debit ? billAmounts : -billAmounts;

          return {
            ...baseLine,
            ...(unitId && { unitId }),
            glId,
            amount,
            description,
          };
        }),
      ],
      notes:
        values?.noteText && currentUser?.name
          ? [{ createdName: currentUser.name, text: values.noteText }]
          : undefined,
      ...(values.recurrence ? { recurrence: values.recurrence } : {}),
    };

    let newjeId: string | undefined = '';

    try {
      const result = await addJournalEntryMutation({
        variables: { input: jeInput },
        update(cache, { data }) {
          const id = cache.identify({ accountId, __typename: 'Books' });
          if (data?.addJournalEntry?.success && data?.addJournalEntry.recurrence) {
            cache.modify({
              id,
              fields: {
                recurrences: (existing = []) => [
                  ...existing,
                  cache.writeFragment({
                    data: data.addJournalEntry.recurrence,
                    fragment: RecurrenceFieldsFragmentDoc,
                    fragmentName: API.Fragment.RecurrenceFields,
                  }),
                ],
              },
            });
          }
        },
      });

      newjeId = result.data?.addJournalEntry?.journalEntries?.[0]?.jeId;

      sendNotification('New expense has been added', 'success');
    } catch (e) {
      console.error(e);
      sendNotification('Error adding expense', 'error');
    }

    try {
      if (newjeId && filesToUpload.length > 0) {
        await uploadFiles({ id: newjeId });
      }
    } catch (e) {
      console.error(e);
      sendNotification('There was an error uploading files', 'error');
    }
  };

  const defaultValues = useMemo(() => {
    const journalLines = ensureArray(data?.journalEntry?.lines);
    const lines = journalLines
      .filter(({ glId }) => findGlAccount(glId)?.accountType === AccountType.Expense)
      .map((line) => ({
        unitId: line.unitId,
        glId: line.glId,
        description: line.description,
        billAmounts: line.amount,
      }));
    const gstLine = journalLines.find(({ glId }) => glId === gstId);
    const paymentAccountLine = journalLines.find(
      ({ glId }) => findGlAccount(glId)?.accountType !== AccountType.Expense && glId !== gstId
    );

    return {
      glId: paymentAccountLine?.glId,
      ownerId: paymentAccountLine?.ownerId,
      propertyId: paymentAccountLine?.propertyId,
      posted: DateTime.now().toISODate(),
      ref: data?.journalEntry?.ref,
      lines: lines ?? [
        {
          unitId: '',
          glId: '',
          description: '',
          billAmounts: 0,
        },
      ],
      noteText: data?.journalEntry?.notes?.[0]?.text,
      payee: data?.journalEntry?.payee ?? PayeeType.Supplier,
      payeeId: data?.journalEntry?.payeeId,
      applyGstHst: Boolean(gstLine),
      gst: gstLine ? gstLine.amount : 0,
    };
  }, [data, gstId]);

  return {
    enterExpense,
    defaultValues,
  };
};
