import { RecurrenceFieldsFragment, useDeleteRecurrenceMutation, useGetRecurrencesQuery } from 'api';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { find, isEqual, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { RRule } from 'rrule';
import { ensureArray, formatNth } from 'system';
import { formatNextRecurrence, isoTo5545, nextRecurrence } from 'system/util/recurrence';
import { dueOffsetOptions } from '../utils';

export type RecurrenceFormFields = {
  name: string;
  startDate: string;
  endDate?: string;
  noEnd: boolean;
  interval: string;
  dueOffset?: number;
};

export type RecurrenceFieldValues = {
  posted: string;
  recurrence?: { name: string; rrule: string; dueOffset?: number };
};

export const useRecurrence = () => {
  const intervals = (startDate: string) => {
    const base = DateTime.fromISO(startDate);
    const { day, month } = base;
    const date = base.toLocaleString({ month: 'long', day: 'numeric' });

    const dateRules = isNaN(Number(day))
      ? []
      : [
          {
            value: 'dayOfMonth',
            label: `The ${formatNth(day)} of each month`,
            order: '1.2',
            rrule: `RRULE:FREQ=MONTHLY;BYMONTHDAY=${day}`,
          },
          {
            value: 'dateOfYear',
            label: `On ${date} of each year`,
            order: '3.2',
            rrule: `RRULE:FREQ=YEARLY;BYMONTHDAY=${day};BYMONTH=${month}`,
          },
          {
            value: 'weekly',
            label: 'Every week on this day',
            order: '0.1',
            rrule: `RRULE:FREQ=WEEKLY;BYDAY=${(base?.weekdayLong ?? 'Monday').slice(0, 2).toUpperCase()}`,
          },
          {
            value: 'biweekly',
            label: 'Every two weeks on this day',
            order: '0.2',
            rrule: `RRULE:FREQ=WEEKLY;INTERVAL=2;BYDAY=${(base?.weekdayLong ?? 'Monday').slice(0, 2).toUpperCase()}`,
          },
          {
            value: 'everyTwoMonths',
            label: 'Every two months on this day',
            order: '1.8',
            rrule: `RRULE:FREQ=MONTHLY;INTERVAL=2;BYMONTHDAY=${day}`,
          },
        ];

    return sortBy(
      [
        ...dateRules,
        {
          value: 'semimonthly',
          label: 'On the 1st and 15th of each month',
          order: '1.15',
          rrule: 'RRULE:FREQ=MONTHLY;BYMONTHDAY=1,15',
        },
        {
          value: 'firstOfMonth',
          label: 'First day of each month',
          order: '1.1',
          rrule: 'RRULE:FREQ=MONTHLY;BYMONTHDAY=1',
        },
        {
          value: 'lastOfMonth',
          label: 'Last day of each month',
          order: '1.3',
          rrule: 'RRULE:FREQ=MONTHLY;BYMONTHDAY=-1',
        },
        {
          value: 'firstEvery3Months',
          label: 'First day every 3 months',
          order: '2.1',
          rrule: 'FREQ=MONTHLY;INTERVAL=3;BYMONTHDAY=1',
        },
        {
          value: 'lastEvery3Months',
          label: 'Last day every 3 months',
          order: '2.3',
          rrule: 'FREQ=MONTHLY;INTERVAL=3;BYMONTHDAY=-1',
        },
        {
          value: 'firstOfYear',
          label: 'First day of each year',
          order: '3.1',
          rrule: 'RRULE:FREQ=YEARLY;BYYEARDAY=1',
        },
        {
          value: 'lastOfYear',
          label: 'Last day of each year',
          order: '3.3',
          rrule: 'RRULE:FREQ=YEARLY;BYYEARDAY=-1',
        },
      ],
      'order'
    );
  };

  const nextPostDate = (values: Omit<RecurrenceFormFields, 'name'>) => {
    const rrule = getRrule(values);
    return formatNextRecurrence(rrule);
  };

  const parseRrule = (rruleString: string) => {
    const rrule = RRule.fromString(rruleString);
    const result: Partial<RecurrenceFormFields> = {
      noEnd: true,
    };

    if (rrule.options.dtstart) {
      result.startDate = DateTime.fromJSDate(rrule.options.dtstart).toISODate() ?? '';
    }

    if (rrule.options.until) {
      result.endDate = DateTime.fromJSDate(rrule.options.until).toISODate() ?? '';
      result.noEnd = false;
    }

    const inputRuleOptions = {
      freq: rrule.options.freq,
      interval: rrule.options.interval,
      bymonthday: rrule.options.bymonthday,
      bymonth: rrule.options.bymonth,
      byyearday: rrule.options.byyearday,
      byweekday: rrule.options.byweekday,
    };

    // Find matching interval by comparing core rule properties
    const matchingInterval = intervals(result.startDate ?? '').find((int) => {
      const intervalRrule = RRule.fromString(int.rrule);
      return (
        intervalRrule.options.freq === inputRuleOptions.freq &&
        intervalRrule.options.interval === inputRuleOptions.interval &&
        isEqual(intervalRrule.options.bymonthday, inputRuleOptions.bymonthday) &&
        isEqual(intervalRrule.options.bymonth, inputRuleOptions.bymonth) &&
        isEqual(intervalRrule.options.byyearday, inputRuleOptions.byyearday) &&
        isEqual(intervalRrule.options.byweekday, inputRuleOptions.byweekday)
      );
    });

    if (matchingInterval) {
      result.interval = matchingInterval.value;
    }

    return result;
  };

  const getRrule = ({
    interval,
    startDate,
    noEnd,
    endDate = '',
  }: Omit<RecurrenceFormFields, 'name'>) => {
    const { rrule = '' } = find(intervals(startDate), { value: interval }) ?? {};
    const until =
      noEnd || !isoTo5545(endDate ? endDate : '')
        ? ''
        : `;UNTIL=${isoTo5545(endDate ? endDate : '')}`;
    return `DTSTART:${isoTo5545(startDate)}\n${rrule}${until}`;
  };

  return { intervals, getRrule, nextPostDate, dueOffsetOptions, parseRrule };
};

export const useRecurrences = () => {
  const { data, error: getError, loading } = useGetRecurrencesQuery();
  const [deleteRecurrenceMutation, { error: deleteError }] = useDeleteRecurrenceMutation();
  useAllErrors(getError, deleteError);

  const [recurrences, setRecurrences] = useState<RecurrenceFieldsFragment[]>([]);

  useEffect(() => {
    setRecurrences(ensureArray(data?.account?.books?.recurrences));
  }, [data]);

  const deleteRecurrence = async (id: string) => {
    await deleteRecurrenceMutation({
      variables: { id },
      update(cache, result) {
        if (result.data?.deleteRecurrence?.success) {
          cache.evict({ id: cache.identify({ id, __typename: 'Recurrence' }) });
          cache.gc();
        }
      },
    });
  };

  const nextPostDate = (rrule?: string) => (rrule ? nextRecurrence(rrule) : undefined);

  return { recurrences, loading, nextPostDate, deleteRecurrence };
};
