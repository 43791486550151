import { Help, TipsAndUpdates } from '@mui/icons-material';
import { Box, IconButton, Link, Stack, Typography } from '@mui/material';
import { useFlags } from 'flagsmith/react';
import { compact } from 'lodash';
import { memo, ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useMatch, useResolvedPath } from 'react-router-dom';
import { CSSLinearProgress } from './CSSLinearProgress';
import { Header, Paper, TabIcon, TabLabel, TabStrip, Title } from './PageHeader.styles';

type Tab = {
  value: string;
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
  skipRoot?: boolean;
};

const TabLink = ({
  tab,
  initialTab,
  rootpath,
}: {
  tab: Tab;
  initialTab?: string;
  rootpath?: string;
}) => {
  const resolvedRoot = useResolvedPath(`/${rootpath}/`);
  const resolvedTab = useResolvedPath(`/${rootpath}/${tab.value}`);

  const matchRoot = useMatch({ path: resolvedRoot.pathname, end: true });
  const matchTab = useMatch({ path: resolvedTab.pathname, end: true });
  const active = Boolean(matchTab || (matchRoot && tab.value === initialTab));

  return (
    <Link
      style={{ textDecoration: 'none' }}
      href={`${tab.skipRoot ? '' : `/${rootpath}`}/${tab.value.replace('/*', '')}`}
      key={tab.value}
    >
      <TabLabel active={active}>
        {tab.icon && <TabIcon active={active}>{tab.icon}</TabIcon>}
        {tab.label}
      </TabLabel>
    </Link>
  );
};

const HeadwayWidget = () => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).Headway?.init({
      selector: '#headway',
      account: 'xa1Y4x',
      trigger: '#headwayButton',
      callbacks: {
        onWidgetReady: () => {
          setReady(true);
        },
      },
    });
  }, []);

  return (
    <>
      <IconButton id="headwayButton" disabled={!ready}>
        <TipsAndUpdates />
      </IconButton>
      <Box
        id="headway"
        component="span"
        sx={{
          width: 0,
          '& .HW_badge': {
            left: (theme) => `${theme.spacing(-2)} !important`,
            top: (theme) => `${theme.spacing(3)} !important`,
          },
        }}
      />
    </>
  );
};

function PageHeader({
  title,
  banner,
  children,
  pageTitle,
  pageTitleComponent,
  pageSubtitle,
  tabs,
  initialTab,
  rootpath,
  hidePageTitle,
  loading,
  loadingText,
  toolbarItems,
}: {
  banner?: ReactNode;
  children?: ReactNode;
  title: string;
  pageTitle?: string;
  pageTitleComponent?: ReactNode;
  pageSubtitle?: string;
  rootpath?: string;
  initialTab?: string;
  tabs?: Tab[];
  hidePageTitle?: boolean;
  loading?: boolean;
  loadingText?: string;
  toolbarItems?: ReactNode[];
}) {
  const flags = useFlags(['intercom_help']);

  return (
    <Stack justifyContent="space-between" gap={1} flexGrow={1}>
      <Helmet>
        <title>{compact([pageSubtitle, pageTitle, title]).join(' | ')} | Propra</title>
      </Helmet>

      <Paper noBorderRadius={Boolean(children)} elevation={0}>
        <Title variant="h4" component="h1">
          {loading ? (loadingText ?? title) : title}
        </Title>
        {tabs?.[0] && (
          <TabStrip>
            {tabs.map((tab) => (
              <TabLink
                key={tab.value}
                initialTab={initialTab ?? tabs[0].value}
                {...{ tab, rootpath }}
              />
            ))}
          </TabStrip>
        )}
        <Stack direction="row">
          {toolbarItems}
          <HeadwayWidget />
          {flags.intercom_help.enabled ? (
            <IconButton
              component="a"
              href="https://intercom.help/propra"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Help sx={{ color: 'text.primary' }} />
            </IconButton>
          ) : (
            <IconButton
              component="a"
              href="https://propra.freshdesk.com/support/home"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Help sx={{ color: 'text.primary' }} />
            </IconButton>
          )}
        </Stack>
      </Paper>
      {children && <Header>{children}</Header>}
      <Stack>
        {banner && (
          <Box sx={{ mb: 1, display: 'flex', flex: 1, flexDirection: 'row' }}>{banner}</Box>
        )}
        {pageTitleComponent ? (
          pageTitleComponent
        ) : pageTitle && !hidePageTitle ? (
          <Typography variant="h4" marginTop={2}>
            {pageTitle}
          </Typography>
        ) : (
          <></>
        )}

        {pageSubtitle && (
          <Typography variant="subtitle1" whiteSpace="pre-line">
            {pageSubtitle}
          </Typography>
        )}
        {loading && <CSSLinearProgress />}
      </Stack>
    </Stack>
  );
}

export default memo(PageHeader);
