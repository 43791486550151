import { Box, Collapse } from '@mui/material';
import { DataGridPro, GridColDef, GridRowModel, gridClasses } from '@mui/x-data-grid-pro';
import { AccountType } from 'api';
import { FullSizePaper, GridSearchToolbar } from 'components';
import { usePageSize } from 'hooks/usePageSize';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { GLAccountAutocompleteController } from '../../../components/Accounting/GLAccountAutocompleteController';
import { FinancialSettingsFormFields, useGlAccounts } from '../hooks';
import { FeeMapping, useFeeMapping } from '../hooks/useFeeMapping';
import { formatAccountName } from '../utils';
import FinancialSettingsToolBar from './FinancialSettingsToolBar';

type FeeRowModel = GridRowModel<FeeMapping>;

export default function FeeMappingForm({ isCollapsible = true }: { isCollapsible?: boolean }) {
  const { feeMapping } = useFeeMapping();
  const { glAccounts } = useGlAccounts();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { setValue } = useFormContext<FinancialSettingsFormFields>();
  useEffect(() => {
    feeMapping.forEach(({ feeId, glId }) => {
      setValue(`feeMapping.${feeId}`, glId);
    });
  }, [feeMapping, setValue]);

  const columns: Array<GridColDef<FeeRowModel>> = [
    {
      field: 'glId',
      headerName: 'Account',
      flex: 1.5,
      renderCell({ row }) {
        return (
          <GLAccountAutocompleteController
            variant="outlined"
            name={`feeMapping.${row.id}`}
            size="small"
            manualSort
            filter={({ accountType, category }) =>
              accountType === AccountType.Revenue ||
              (accountType === AccountType.Liability && category !== 'accountsPayable')
            }
            sort={(a, b) =>
              a.accountType === b.accountType
                ? formatAccountName(a).localeCompare(formatAccountName(b))
                : b.accountType.localeCompare(a.accountType)
            }
            sx={{ my: 2 }}
          />
        );
      },
    },
    {
      field: 'description',
      headerName: 'Monthly Fee',
      flex: 2,
    },
  ];

  const pageConfig = usePageSize({ cacheKey: 'fee-mapping', defaultPageSize: 100 });

  return glAccounts.length === 0 ? (
    <></>
  ) : (
    <FullSizePaper sx={{ mb: 2, ...(isCollapsible ? { pt: 2, pb: 1.5 } : { p: 0, border: 0 }) }}>
      {isCollapsible && (
        <FinancialSettingsToolBar
          open={isOpen}
          onClick={() => setIsOpen((prevState) => !prevState)}
          title="Fee Mapping"
        />
      )}
      <Collapse in={isOpen || !isCollapsible}>
        <Box sx={{ height: '100%', width: '100%', mt: 1 }}>
          <DataGridPro
            autoHeight
            disableColumnReorder
            disableRowSelectionOnClick
            disableColumnPinning
            disableColumnMenu
            disableMultipleRowSelection
            isRowSelectable={() => false}
            {...pageConfig}
            getRowHeight={() => 'auto'}
            rows={feeMapping}
            columns={columns}
            slots={{ toolbar: GridSearchToolbar }}
            hideFooter={true}
            sx={{
              [`& .${gridClasses.cell}:focus`]: { outline: 'none' },
              [`& .${gridClasses.cell}:focus-within`]: { outline: 'none' },
            }}
          />
        </Box>
      </Collapse>
    </FullSizePaper>
  );
}
