import { ReactiveVar, useReactiveVar } from '@apollo/client';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { MutableRefObject, useEffect } from 'react';

export const useGridState = (
  state: ReactiveVar<GridInitialStatePro>,
  { apiRef: _apiRef }: { apiRef?: MutableRefObject<GridApiPro> } = {}
) => {
  const internalApiRef = useGridApiRef();
  const initialState = useReactiveVar(state);
  const apiRef = _apiRef ?? internalApiRef;

  useEffect(() => {
    const currentGrid = apiRef.current;
    return () => {
      currentGrid?.exportState && state(currentGrid.exportState());
    };
  }, [apiRef, state]);

  return { apiRef, initialState };
};
