import {
  useOwnerEntityNamesQuery,
  usePropertyEntityNamesQuery,
  useSearchRelatedUnitsQuery,
} from 'api';
import { useMeta } from 'hooks/useMeta';
import { compact } from 'lodash';
import { useAccount } from 'pages/account/hooks/useAccount';
import { useMemo } from 'react';
import { emptyArray } from 'system/constant';
import { ensureArray } from 'system/util';

export const useEntityDetailsSingle = ({
  unitId,
  ownerId,
  propertyId,
}: {
  unitId?: string;
  ownerId?: string;
  propertyId?: string;
} = {}) => {
  const ownerMeta = useOwnerEntityNamesQuery({
    variables: { ownerIds: ownerId ? [ownerId] : emptyArray },
    skip: !ownerId,
  });

  const propertyMeta = usePropertyEntityNamesQuery({
    variables: { propertyIds: propertyId ? [propertyId] : emptyArray },
    skip: !propertyId,
  });

  const unitMeta = useSearchRelatedUnitsQuery({
    variables: { filter: { unitId } },
    skip: !unitId,
  });

  const { account } = useAccount();

  const { loading } = useMeta(ownerMeta, propertyMeta, unitMeta);

  const [owner] = useMemo(
    () =>
      compact([
        ...(ownerId
          ? ownerId === account?.id
            ? [account]
            : ensureArray(ownerMeta.data?.account?.pageOwners?.edges).map(({ node }) => node)
          : propertyId
            ? ensureArray(propertyMeta.data?.account?.pageProperties?.edges).map(({ node }) => ({
                id: node.ownerId ?? account?.id,
                name: node.ownerName ?? account?.name,
                address: node.address,
              }))
            : []),
      ]),
    [ownerMeta.data, propertyMeta.data, account, ownerId, propertyId]
  );

  const [property] = useMemo(
    () => ensureArray(propertyMeta.data?.account?.pageProperties?.edges).map(({ node }) => node),
    [propertyMeta.data]
  );

  const [unit] = useMemo(
    () => ensureArray(unitMeta.data?.account?.books?.searchRelatedUnits?.units),
    [unitMeta.data]
  );

  const entityName = useMemo(() => {
    const buildingPart = unit?.buildingKey ?? unit?.buildingName;
    const propertyPart = unit
      ? unit.propertyKey
        ? `[${unit.propertyKey}] ${unit.propertyName}`
        : unit.propertyName
      : property?.key
        ? `[${property.key}] ${property.name}`
        : property?.name;

    return unit
      ? `${propertyPart}${buildingPart ? `(${buildingPart})` : ''} - ${unit.unitName}`
      : propertyPart;
  }, [unit, property]);

  const ownerName = useMemo(
    () => (owner?.id === account?.id ? account?.name : owner?.name),
    [owner, account, ownerId]
  );

  const propertyName = useMemo(
    () => (propertyId === account?.id ? account?.name : (property?.name ?? unit?.propertyName)),
    [property, account, propertyId, unit]
  );

  return {
    loading,
    entityName,
    ownerName,
    unitName: unit?.unitName,
    buildingName: unit?.buildingName,
    propertyName,
    propertyAddress: property?.address,
  };
};
