import { Reference } from '@apollo/client';
import {
  AddClearableInput,
  API,
  BalanceType,
  ClearableFieldsFragmentDoc,
  GstInfo,
  ListClearablesQueryVariables,
  PayeeType,
  RecurrenceFieldsFragmentDoc,
  useAddClearableMutation,
  useGetJournalEntryQuery,
} from 'api';
import { useAuth } from 'context';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useMeta } from 'hooks/useMeta';
import { useNotification } from 'hooks/useNotification';
import { UploadError, UploadSuccess } from 'hooks/useUploadFiles';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { ensureArray, invalidate, safeSum } from 'system';
import { EnterBillFormFields } from '../tasks/EnterBill/schema';
import { clearablesFilterMatches, storeFieldNameArgs } from '../utils';
import { useGlAccounts } from './useGlAccounts';
import { useGlMapping } from './useGlMapping';

export const useEnterBill = (sourceJournalId = '') => {
  const { sendNotification } = useNotification();
  const { mappedGlId } = useGlMapping();
  const { findGlAccount } = useGlAccounts();
  const [addClearableMutation, addClearableMeta] = useAddClearableMutation();
  const { data, ...journalEntryMeta } = useGetJournalEntryQuery({
    variables: { id: sourceJournalId },
    skip: !sourceJournalId,
  });
  const journalEntry = data?.journalEntry;
  useMeta(journalEntryMeta, addClearableMeta);

  const { accountId } = useAuth();
  const { currentUser } = useCurrentUser();

  const apId = mappedGlId('accountsPayable') ?? '';
  const gstId = mappedGlId('gstHstPayable') ?? '';

  const enterBill = async (
    values: EnterBillFormFields,
    filesToUpload: File[],
    uploadFiles: ({ id }: { id: string }) => Promise<{
      successfulUploads: UploadSuccess[];
      failedUploads: UploadError[];
    }>
  ) => {
    const total = values.lines.reduce((result, line) => safeSum(result, line.billAmounts), 0);

    const baseLine = {
      ownerId: values.ownerId,
      propertyId: values.propertyId,
      ref: values.ref,
    };

    const firstUnitId = values.lines[0].unitId;
    const input: AddClearableInput = {
      posted: values.posted,
      due: values.due,
      payee: values.payee,
      payeeId: values.payeeId,
      supplierAccount: values.supplierAccount,
      lines: [
        {
          ...baseLine,
          glId: apId,
          ...(firstUnitId && { unitId: firstUnitId }),
          amount: values.applyGstHst ? total + (values.gst ?? 0) : total,
          description: values.lines[0].description,
        },
        ...(values.applyGstHst
          ? [
              {
                ...baseLine,
                glId: gstId,
                ...(firstUnitId && { unitId: firstUnitId }),
                amount: (values.gst ?? 0) * -1,
                description: values.lines[0].description,
                gstInfo: GstInfo.Line106,
              },
            ]
          : []),
        ...values.lines.map(({ glId, unitId, billAmounts, description }) => {
          const lineGlAccount = findGlAccount(glId);
          const amount =
            lineGlAccount?.balanceType === BalanceType.Debit ? billAmounts : -billAmounts;
          return {
            ...baseLine,
            glId,
            ...(unitId && { unitId }),
            amount,
            description,
          };
        }),
      ],
      notes:
        values?.noteText && currentUser?.name
          ? [{ createdName: currentUser.name, text: values.noteText }]
          : undefined,
      ...(values.recurrence ? { recurrence: values.recurrence } : {}),
    };

    try {
      const { data } = await addClearableMutation({
        variables: {
          input,
        },
        update(cache, { data: mutationResponse }) {
          const id = cache.identify({ accountId, __typename: 'Books' });
          if (mutationResponse?.addClearable?.success && mutationResponse.addClearable.clearable) {
            const cachedClearable = mutationResponse.addClearable.clearable;
            const ref = cache.writeFragment({
              data: cachedClearable,
              fragment: ClearableFieldsFragmentDoc,
              fragmentName: API.Fragment.ClearableFields,
            });
            cache.modify({
              id,
              fields: {
                journalEntriesForPayeeRef: invalidate,
                listClearables: (existing = {}, { storeFieldName }) => {
                  const items = ensureArray<Reference[]>(existing.items);
                  const args = storeFieldNameArgs<ListClearablesQueryVariables>(storeFieldName);
                  const matches = clearablesFilterMatches(args.filter, cachedClearable);
                  return matches ? { ...existing, items: [...items, ref] } : existing;
                },
                payeeClearables: (existing = { items: [] }, { storeFieldName }) => {
                  const payeeId = cachedClearable.payeeId;
                  return payeeId && storeFieldName.includes(payeeId)
                    ? {
                        ...existing,
                        items: [...existing.items, ref],
                      }
                    : existing;
                },
              },
            });
          }
          if (
            mutationResponse?.addClearable?.success &&
            mutationResponse?.addClearable.recurrence
          ) {
            cache.modify({
              id,
              fields: {
                recurrences: (existing = []) => [
                  ...existing,
                  cache.writeFragment({
                    data: mutationResponse.addClearable.recurrence,
                    fragment: RecurrenceFieldsFragmentDoc,
                    fragmentName: API.Fragment.RecurrenceFields,
                  }),
                ],
              },
            });
          }
        },
      });

      const id = data?.addClearable.clearable?.sourceJournalEntry.jeId;

      if (id && filesToUpload.length > 0) {
        await uploadFiles({ id });
      }

      sendNotification('New bill has been posted', 'success');
    } catch (e) {
      sendNotification('Error adding bill', 'error');
    }
  };

  const defaultValues = useMemo(() => {
    const applyGst = journalEntry?.lines.some((line) => line.glId === gstId);
    const gstHst = journalEntry?.lines.find((line) => line.glId === gstId)?.amount ?? 0;

    const lines = journalEntry?.lines
      .filter((line) => ![gstId, apId].includes(line.glId))
      .map((bill) => {
        return {
          glId: bill.glId,
          ownerId: bill.ownerId,
          propertyId: bill.propertyId,
          clearableId: bill.clearableId,
          billAmounts: bill.amount,
          description: bill.description,
          unitId: bill.unitId,
        };
      });
    return {
      payeeId: journalEntry?.payeeId,
      payee: journalEntry?.payee ?? PayeeType.Supplier,
      ownerId: journalEntry?.ownerId,
      propertyId: journalEntry?.propertyId,
      due: DateTime.now().toISODate(),
      posted: DateTime.now().toISODate(),
      ref: journalEntry?.ref,
      lines: lines ?? [
        {
          unitId: '',
          glId: '',
          description: '',
          billAmounts: 0,
        },
      ],
      noteText: journalEntry?.notes?.[0]?.text,
      applyGstHst: applyGst ?? false,
      gst: gstHst,
    };
  }, [journalEntry, gstId, apId]);

  return {
    enterBill,
    defaultValues,
  };
};
