import { uniq } from '@propra-system/registry';
import {
  AddClearableInput,
  AddJournalEntryInput,
  BalanceType,
  PayeeType,
  PaymentMethod,
  useAbortBooksBatchTaskMutation,
  useAddClearableMutation,
  useAddClearingEntryBatchTaskMutation,
  useAddJournalEntryMutation,
  useUpdateClearingEntryBatchTaskMutation,
} from 'api';
import { useMeta } from 'hooks/useMeta';
import { useNotification } from 'hooks/useNotification';
import { chain, compact, isError, map, noop, sumBy } from 'lodash';
import { chunk } from 'lodash/fp';
import { useGlMapping } from 'pages/accounting/hooks';
import plur from 'plur';
import { useCallback, useState } from 'react';
import { concurrent, concurrentMutations, ksuid, matches, safeRound } from 'system';
import { ServerClearableItem } from '../clearables/useServerClearables';
import { useBatchContext } from '../context';
import { ClearableLabels } from '../types';
import { subAccountHolder } from '../utils';
import { useClearableCache } from './cache';
import { useGlAccounts } from './useGlAccounts';

export type ClearingEntryLine = {
  clearableId: string;
  clearingEntryAmount: number;
  propertyId: string;
  ownerId: string;
  unitId?: string;
  glId: string;
  selected: boolean;
};

export type ClearableRow = ServerClearableItem & {
  index: number;
  payeeIndex: number;
  journalId?: string;
  clearableId: string;
  originalClearingAmount?: number;
  clearingAmount?: number;
  payee: string;
};

export type ClearingEntryValues = {
  payments?: boolean;
  glId: string;
  posted: string;
  total: number;
  paymentMethod?: PaymentMethod;
  payees: {
    payee?: PayeeType;
    payeeId: string;
    payeeName?: string;
    payeeClearables: ClearableRow[];
    ref?: string;
    description?: string;
    total: number;
    prevTotal?: number;
    unitId?: string;
    ownerId?: string;
    propertyId?: string;
    entityName?: string;
  }[];
};

type RequiredNewClearableFields = Required<
  Pick<
    ClearingEntryValues['payees'][number],
    'payee' | 'ownerId' | 'propertyId' | 'total' | 'payeeClearables'
  >
>;

const validNewClearable = <T extends Partial<RequiredNewClearableFields>>(
  p: T
): p is T & RequiredNewClearableFields =>
  Boolean(
    p.payee &&
      p.ownerId &&
      p.propertyId &&
      p.total &&
      p.payeeClearables &&
      p.payeeClearables.length === 0
  );

export const useClearingEntry = ({ balanceType, ...labels }: ClearableLabels) => {
  const { sendNotification } = useNotification();
  const { findGlAccount } = useGlAccounts();
  const { mappedGlId } = useGlMapping();
  const { addBatchListener, updateBatchListener, removeBatchListener } = useBatchContext();
  const [addJournalEntryMutation, addJournalMeta] = useAddJournalEntryMutation();
  const [addClearableMutation, addClearableMeta] = useAddClearableMutation();
  const [addClearingEntryBatchMutation, addEntryBatchMeta] = useAddClearingEntryBatchTaskMutation();
  const [updateClearingEntryBatchMutation, updateEntryBatchMeta] =
    useUpdateClearingEntryBatchTaskMutation();
  const [abortBooksBatchTaskMutation, abortBooksBatchMeta] = useAbortBooksBatchTaskMutation();

  const [rawBatchId, setRawBatchId] = useState(() => ksuid());
  const batchId = `post-${rawBatchId}`;
  const batchIdDisplay = batchId.slice(-5);

  const { loading } = useMeta(
    addJournalMeta,
    addClearableMeta,
    addEntryBatchMeta,
    updateEntryBatchMeta,
    abortBooksBatchMeta
  );

  const { updateClearableCache } = useClearableCache();

  const clearableGlId =
    balanceType === BalanceType.Debit
      ? (mappedGlId('accountsReceivable') ?? '')
      : (mappedGlId('accountsPayable') ?? '');

  const invalidClearingEntry = useCallback(
    (values: ClearingEntryValues) => {
      const clearablesToClear = values.payees.flatMap(({ payeeClearables }) => payeeClearables);
      const clearablesToCreate = values.payees.filter(validNewClearable);

      if (!clearablesToClear.some((l) => l.clearingAmount) && !clearablesToCreate.length) {
        sendNotification('Nothing has been selected to post', 'error');
        return true;
      }

      const totals = values.payees.map(({ total }) => total);
      if (totals.some((total) => total < 0)) {
        sendNotification(
          `Total ${plur(labels.clearingEntryLabel.toLowerCase())} can't be less than $0.00`,
          'error'
        );
        return true;
      }
    },
    [labels.clearingEntryLabel, sendNotification]
  );

  const addClearingEntries = useCallback(
    async (values: ClearingEntryValues) => {
      if (invalidClearingEntry(values)) {
        return { success: false };
      }

      const addClearableInputs: AddClearableInput[] = compact(
        values.payees.filter(validNewClearable).flatMap((payeeData) => [
          {
            posted: values.posted,
            due: values.posted,
            payee: payeeData.payee,
            payeeId: payeeData.payeeId,
            lines: [
              {
                glId: clearableGlId,
                ownerId: payeeData.ownerId,
                propertyId: payeeData.propertyId,
                amount: safeRound(-payeeData.total),
                description: payeeData.description,
                ref: payeeData.ref,
                payee: payeeData.payee,
                payeeId: payeeData.payeeId,
                paymentMethod: values.paymentMethod,
                ...(payeeData.unitId && {
                  unitId: payeeData.unitId,
                }),
              },
              {
                glId: values.glId,
                ownerId: payeeData.ownerId,
                propertyId: payeeData.propertyId,
                amount:
                  findGlAccount(values.glId)?.balanceType === balanceType
                    ? safeRound(payeeData.total)
                    : safeRound(-payeeData.total),
                description: payeeData.description,
                ref: payeeData.ref,
                payee: payeeData.payee,
                payeeId: payeeData.payeeId,
                paymentMethod: values.paymentMethod,
                ...(payeeData.unitId && {
                  unitId: payeeData.unitId,
                }),
              },
            ],
          },
        ])
      );

      const addJournalInputs = compact(
        values.payees
          .filter(({ payeeClearables }) =>
            payeeClearables.some(({ clearingAmount }) => clearingAmount)
          )
          .flatMap(({ ref, description, payeeClearables }) =>
            chain(payeeClearables)
              .filter('clearingAmount')
              .groupBy(subAccountHolder)
              .values()
              .flatMap<ClearableRow[]>(chunk(39))
              .value()
              .map<AddJournalEntryInput>((clearableLines) => {
                const { ownerId, propertyId, payee, payeeId } = clearableLines[0];
                const payeeName = clearableLines[0].payeeName;

                const total = sumBy(clearableLines, 'clearingAmount');
                const unitIds = uniq(map(clearableLines, 'unitId'));
                const unitId = unitIds.length === 1 ? unitIds[0] : undefined;

                const baseLine = {
                  ownerId,
                  propertyId,
                  unitId,
                  ...(ref && { ref }),
                  payee,
                  payeeId,
                  description:
                    description ?? [labels.clearingEntryDescription, payeeName].join(' '),
                  paymentMethod: values.paymentMethod,
                };

                const glAccount = findGlAccount(values.glId);
                return {
                  posted: values.posted,
                  lines: [
                    {
                      ...baseLine,
                      batchId,
                      glId: values.glId,
                      amount: glAccount?.balanceType === balanceType ? total : -total,
                    },
                    ...clearableLines.map(({ id, glId, clearingAmount = 0 }) => ({
                      ...baseLine,
                      clearableId: id,
                      glId,
                      amount:
                        findGlAccount(glId)?.balanceType === balanceType
                          ? -clearingAmount
                          : clearingAmount,
                    })),
                  ],
                };
              })
          )
      );

      try {
        const clearableResponses = await concurrent(
          concurrentMutations,
          addClearableInputs.map(
            (input) => () =>
              addClearableMutation({
                variables: { input },
                update(_cache, { data }) {
                  if (data?.addClearable.success && data.addClearable.clearable) {
                    const clearable = data.addClearable.clearable;
                    updateClearableCache([clearable.id]);
                  }
                },
              })
          )
        );

        const [newClearableIds, updatedClearableIds] = [
          clearableResponses.map(({ data }) => data?.addClearable.clearable?.id),
          addJournalInputs.flatMap((input) => input.lines.map(({ clearableId }) => clearableId)),
        ].map((cids) => uniq(compact(cids)));

        const clearableIds = uniq([...newClearableIds, ...updatedClearableIds]);

        if (clearableIds.length) {
          const label = `${labels.clearingEntryLabel}${newClearableIds.length ? ' Credit' : ''}`;

          await addClearingEntryBatchMutation({
            variables: {
              input: {
                batchId,
                taskInput: {
                  newClearableIds,
                  updatedClearableIds,
                  label,
                },
              },
            },
          });

          addBatchListener({
            id: batchId,
            label,
            total: clearableIds.length,
            kind: 'books',
          });

          updateClearableCache(clearableIds);
        }

        const addJournalResults = await concurrent(
          concurrentMutations,
          addJournalInputs.map(
            (input) => () =>
              addJournalEntryMutation({
                variables: {
                  input,
                },
                update(cache, { data }) {
                  if (data?.addJournalEntry.success) {
                    input.lines
                      .filter(({ glId }) => glId !== values.glId)
                      .forEach(({ clearableId, amount }) =>
                        cache.modify({
                          id: cache.identify({ id: clearableId, __typename: 'Clearable' }),
                          fields: {
                            outstanding(prev) {
                              return prev + amount;
                            },
                            journalEntries(_, { DELETE }) {
                              return DELETE;
                            },
                          },
                        })
                      );
                  }
                },
              })
                .then(({ data }) =>
                  data?.addJournalEntry.success !== false
                    ? { success: true as const, data, input }
                    : { success: false as const, error: data?.addJournalEntry.error, input }
                )
                .catch((error: unknown) => ({ success: false as const, error, input }))
          )
        );

        const addJournalSuccessResponses = addJournalResults.filter(
          matches({ success: true as const })
        );
        const addJournalErrorResponses = addJournalResults.filter(
          matches({ success: false as const })
        );

        if (addJournalSuccessResponses.length) {
          sendNotification(
            `${addJournalSuccessResponses.length} ${plur(
              labels.clearingEntryLabel,
              addJournalSuccessResponses.length
            )} posted`,
            'success'
          );
        }

        if (addJournalErrorResponses.length) {
          sendNotification(
            `${addJournalErrorResponses.length} ${plur(
              labels.clearingEntryLabel,
              addJournalErrorResponses.length
            )} failed to post`,
            'error'
          );

          const failedClearableIds = uniq(
            compact(
              addJournalErrorResponses.flatMap(({ input }) => input.lines.map((l) => l.clearableId))
            )
          );

          const remaining = clearableIds.length - failedClearableIds.length;

          await updateClearingEntryBatchMutation({
            variables: {
              id: batchId,
              input: { total: remaining },
            },
          }).catch(noop);

          if (remaining > 0) {
            updateBatchListener(batchId, { total: remaining });
          } else {
            removeBatchListener(batchId);
          }

          setRawBatchId(ksuid());

          return {
            success: false,
            failedClearableIds,
          };
        }

        return { success: true };
      } catch (e) {
        console.error(e);
        sendNotification(`${labels.clearingEntryLabel} validation error`, 'error');

        if (batchId) {
          removeBatchListener(batchId);

          await abortBooksBatchTaskMutation({
            variables: {
              id: batchId,
              reason: isError(e) ? e.message : `${labels.clearingEntryLabel} validation error`,
            },
          }).catch(noop);
        }

        return { success: false };
      }
    },
    [
      abortBooksBatchTaskMutation,
      addBatchListener,
      addClearableMutation,
      addClearingEntryBatchMutation,
      addJournalEntryMutation,
      balanceType,
      batchId,
      clearableGlId,
      findGlAccount,
      invalidClearingEntry,
      labels.clearingEntryDescription,
      labels.clearingEntryLabel,
      removeBatchListener,
      sendNotification,
      updateBatchListener,
      updateClearableCache,
      updateClearingEntryBatchMutation,
    ]
  );

  const getBatchId = useCallback(() => batchId, [batchId]);

  return {
    loading,
    getBatchId,
    addClearingEntries,
    batchIdDisplay,
  };
};
