import { ProvinceCode } from 'api';
import { compact } from 'lodash';

export const DEFAULT_GST = 0.05;

export const getCondoLabels = (province: keyof typeof ProvinceCode | string | undefined) =>
  province === ProvinceCode.Bc
    ? {
        condo: 'strata',
        unitFactor: 'unit entitlement',
      }
    : {
        condo: 'condo',
        unitFactor: 'unit factor',
      };

export const addressParts = (address?: {
  suite?: string;
  street?: string;
  city?: string;
  province?: string;
  postal?: string;
}) =>
  address
    ? compact([
        compact([address?.suite, address?.street]).join(', '),
        compact([address?.city, address?.province]).join(', '),
        address?.postal,
      ])
    : [];
