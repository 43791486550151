import {
  ChequePlacement,
  FilingFrequency,
  UpdateFinancialSettingsInput,
  useUpdateFinancialSettingsMutation,
  useUpdateGlChequePlacementMutation,
} from 'api';
import { useMeta } from 'hooks/useMeta';
import { useNotification } from 'hooks/useNotification';
import { find, isEmpty, pick } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Categories, ensureArray } from 'system';
import { useBooks } from './useBooks';

export type UpdateGlMappingFormFields = {
  glAccountMapping: {
    [key: string]: string;
  };
};

export type BillingRateFormFields = {
  category?: Categories | string;
  glId?: string;
  enabled: boolean;
  labourRate: string;
  materialsMarkup: string;
  requireApproval: boolean;
  minBillableMinutes: number;
  trackRevenue: boolean;
};

export type FinancialSettingsFormFields = {
  glAccountMapping: {
    [key: string]: string;
  };
  feeMapping: {
    [key: string]: Partial<string>;
  };
  chargeMapping: {
    [key: string]: Partial<string>;
  };
  billingRates: BillingRateFormFields;
  customBillingRates?: BillingRateFormFields[];
  receiverGeneralId?: string;
  yearEndMonth: number;
  gstNumber: string;
  gstFiling?: FilingFrequency;
  chequePlacement: ChequePlacement;
  trackManagementFeeRevenue: boolean;
  includeDisabledProperties: boolean;
  billableRevenueFeeIds: string[];
  incomePayoutOptions?: {
    excludePayables?: boolean;
    excludePrepayments?: boolean;
    includeOutstandingPayables?: boolean;
  };
};

export const DEFAULT_STARTING_CHEQUE = 100;
export const DEFAULT_STARTING_INVOICE = 1000;
export const DEFAULT_STARTING_JOURNAL = 1;

export const useFinancialSettings = () => {
  const { sendNotification } = useNotification();
  const navigate = useNavigate();
  const { books, ...booksMeta } = useBooks();
  const [updateFinancialSettingsMutation, updateSettingsMeta] =
    useUpdateFinancialSettingsMutation();
  const [updateGlChequePlacementMutation, updateChequeMeta] = useUpdateGlChequePlacementMutation();

  // Just error handling
  useMeta(booksMeta, updateSettingsMeta, updateChequeMeta);

  const lastPrintedGlId = useMemo(() => books?.lastPrintedGlId, [books]);

  const [queryLoading, setQueryLoading] = useState(true);
  const [billingRates, setBillingRates] = useState<
    | {
        enabled?: boolean;
        labourRate?: number;
        materialsMarkup?: number;
        requireApproval?: boolean;
        minBillableMinutes?: number;
        trackRevenue?: boolean;
      }
    | undefined
  >();

  const [nextInvoice, setNextInvoice] = useState<number>(DEFAULT_STARTING_INVOICE);
  const [nextJournal, setNextJournal] = useState<number>(DEFAULT_STARTING_JOURNAL);

  const chequeForGlAccount = useCallback(
    (glId: string) => ({
      glId,
      printedZ: books?.printedZ,
      nextCheque: books?.nextCheque ?? DEFAULT_STARTING_CHEQUE,
      ...find(books?.printedCheques, { glId }),
    }),
    [books?.nextCheque, books?.printedCheques, books?.printedZ]
  );

  useEffect(() => {
    if (books) {
      const rates = books.billingRates;
      setBillingRates({
        ...rates,
        ...(rates?.materialsMarkup ? { materialsMarkup: rates.materialsMarkup * 100 } : {}),
      });
      setNextInvoice(books.nextInvoice ?? DEFAULT_STARTING_INVOICE);
      setNextJournal(books.nextJournal ?? DEFAULT_STARTING_JOURNAL);
      setQueryLoading(false);
    }
  }, [books]);

  const updateFinancialSettings = async (
    values: FinancialSettingsFormFields,
    { redirect = true }: { redirect?: boolean }
  ) => {
    const input: UpdateFinancialSettingsInput = {
      ...(!isEmpty(values.glAccountMapping) && {
        glMapping: Object.keys(values.glAccountMapping).map((key) => ({
          id: key,
          glId: values.glAccountMapping[key],
        })),
      }),
      ...(!isEmpty(values.feeMapping) && {
        feeMapping: Object.entries(values.feeMapping)
          .filter(([_, glId]) => glId)
          .map(([feeId, glId]) => ({
            feeId,
            glId,
          })),
      }),
      ...(!isEmpty(values.chargeMapping) && {
        chargeMapping: Object.entries(values.chargeMapping ?? {})
          .filter(([_, glId]) => glId)
          .map(([chargeId, glId]) => ({
            chargeId,
            glId,
          })),
      }),
      ...(!isEmpty(values.incomePayoutOptions) && {
        incomePayoutOptions: values.incomePayoutOptions,
      }),
      billingRates: {
        enabled: values.billingRates.enabled,
        labourRate: parseFloat(values.billingRates.labourRate ?? '0'),
        materialsMarkup: parseFloat(values.billingRates.materialsMarkup ?? '0') / 100,
        requireApproval: values.billingRates.requireApproval,
        minBillableMinutes: values.billingRates.minBillableMinutes,
        trackRevenue: values.billingRates.trackRevenue,
      },
      ...(values.customBillingRates && {
        customBillingRates: values.customBillingRates.map((rate) => ({
          category: rate.category,
          glId: rate.glId,
          enabled: rate.enabled,
          labourRate: parseFloat(rate.labourRate ?? '0'),
          materialsMarkup: parseFloat(rate.materialsMarkup ?? '0') / 100,
          requireApproval: rate.requireApproval,
          minBillableMinutes: rate.minBillableMinutes,
          trackRevenue: rate.trackRevenue,
        })),
      }),
      ...pick(values, [
        'receiverGeneralId',
        'yearEndMonth',
        'gstFiling',
        'gstNumber',
        'chequePlacement',
        'nsfChargeTo',
        'trackManagementFeeRevenue',
        'managementFeeBasis',
        'includeDisabledProperties',
        'billableRevenueFeeIds',
      ]),
    };

    try {
      await updateFinancialSettingsMutation({
        variables: { input },
        update(cache, { data }) {
          ensureArray(data?.updateFinancialSettings.books?.glAccountMapping).forEach((mapping) => {
            cache.modify({
              id: cache.identify({
                __typename: 'GLMapping',
                id: mapping.id,
                parentId: mapping.parentId,
              }),
              fields: {
                glId: () => mapping.glId,
              },
            });
          });
        },
      });
      sendNotification('Financial settings updated', 'success');
      if (redirect) setTimeout(() => navigate('/accounting/accounts'), 1000);
    } catch (e) {
      sendNotification('Financial settings has an error', 'error');
    }
  };

  const updateGlChequePlacement = async ({
    glId,
    chequePlacement,
  }: {
    glId: string;
    chequePlacement: ChequePlacement;
  }) => {
    const result = await updateGlChequePlacementMutation({
      variables: {
        input: { glId, chequePlacement },
      },
    });

    if (!result.data?.updateGLChequePlacement.success) {
      const message = result.data?.updateGLChequePlacement.error ?? 'Error saving cheque placement';
      sendNotification(message, 'error');
    }
  };

  return {
    nextInvoice,
    nextJournal,
    billingRates,
    customBillingRates: books?.customBillingRates,
    updateLoading: updateSettingsMeta.loading,
    updateFinancialSettings,
    queryLoading,
    books,
    chequeForGlAccount,
    updateGlChequePlacement,
    chequePlacementLoading: updateChequeMeta.loading,
    lastPrintedGlId,
  };
};
