import { OwnerListFieldsFragment } from 'api';
import { AutocompleteField, AutocompleteMultiField } from 'components/Fields';
import { useAuth } from 'context/auth';
import { useDebouncedValue } from 'hooks/useDebounce';
import { useServerOwners } from 'hooks/useServerOwners';
import { useValueChangePulse } from 'hooks/useValueChangePulse';
import { compact, uniqBy } from 'lodash';
import { useEntityDetailsSingle } from 'pages/accounting/hooks/useEntityDetailsSingle';
import { useEffect, useMemo, useState } from 'react';
import { ifDifferent, Option, shallowOmit, tuple } from 'system';

type MultipleProps = {
  selectionMode: 'multiple';
  value?: string[];
  defaultValue?: string[];
  onChange?: (owners?: OwnerListFieldsFragment[]) => void;
  filter?: Partial<Parameters<typeof useServerOwners>[0]['filter']>;
} & Omit<
  Partial<Parameters<typeof AutocompleteMultiField<string>>[0]>,
  'options' | 'loading' | 'onInputChange' | 'onChange' | 'value' | 'defaultValue'
>;

type SingleProps = {
  selectionMode?: 'single';
  value?: string | Option<string>;
  defaultValue?: string | Option<string>;
  onChange?: (owner?: OwnerListFieldsFragment) => void;
  filter?: Partial<Parameters<typeof useServerOwners>[0]['filter']>;
} & Omit<
  Partial<Parameters<typeof AutocompleteField<string>>[0]>,
  'options' | 'loading' | 'onInputChange' | 'onChange' | 'value' | 'defaultValue'
>;

const matchSelectionMode = (props: OwnerAutocompleteFieldProps) =>
  props.selectionMode === 'multiple'
    ? tuple(props.selectionMode, shallowOmit(props, ['selectionMode']))
    : tuple(props.selectionMode, shallowOmit(props, ['selectionMode']));

export type OwnerAutocompleteFieldProps = MultipleProps | SingleProps;

export const OwnerAutocompleteField = ({ filter, ...fieldProps }: OwnerAutocompleteFieldProps) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch, debouncing] = useDebouncedValue(search, { delay: 500 });

  const ownerId = fieldProps.value
    ? typeof fieldProps.value === 'string'
      ? fieldProps.value
      : Array.isArray(fieldProps.value)
        ? fieldProps.value[0]
        : fieldProps.value.id
    : undefined;

  const { accountId } = useAuth();
  const { loading, owners, ownerOptions } = useServerOwners({
    filter: { ...filter, search: debouncedSearch },
    onCompleted: (newOwners) => {
      const newOwnerMatch = newOwners.find((o) => o.id === ownerId);
      const propertySpecific = Boolean(filter?.propertyIds?.length);

      if (selectionMode !== 'single' || !propertySpecific) {
        return;
      }

      if (!newOwnerMatch) {
        const autoselectedOwner = [1, 2].includes(newOwners.length)
          ? newOwners.find((o) => newOwners.length === 1 || o.id !== accountId)
          : undefined;

        props.onChange?.(autoselectedOwner);
      } else {
        props.onChange?.(newOwnerMatch);
      }
    },
  });

  const { ownerName } = useEntityDetailsSingle({ ownerId });
  const [selectedOwner, setSelectedOwner] = useState<Option<string> | undefined>(undefined);
  useEffect(() => {
    setSelectedOwner(ifDifferent(ownerName ? { id: ownerId ?? '', text: ownerName } : undefined));
  }, [ownerName, ownerId]);

  const autocompleteOptions = useMemo(
    () => uniqBy(compact([selectedOwner, ...ownerOptions]), 'id'),
    [selectedOwner, ownerOptions]
  );

  const [selectionMode, props] = matchSelectionMode(fieldProps);

  useValueChangePulse(
    filter?.propertyIds,
    (isFirstChange) => {
      if (isFirstChange || selectionMode !== 'single') {
        return;
      }

      props.onChange?.(undefined);
    },
    [props.onChange, selectionMode]
  );

  return selectionMode === 'multiple' ? (
    <AutocompleteMultiField
      variant="filled"
      options={autocompleteOptions}
      loading={loading || debouncing}
      filterOptions={(x) => x}
      {...props}
      onInputChange={(_, value) => setSearch(value)}
      onSelected={(newValue) => {
        props.onChange?.(owners.filter(({ id }) => newValue.includes(id)));
      }}
    />
  ) : (
    <AutocompleteField
      keepSelected
      variant="filled"
      options={autocompleteOptions}
      hideIcons={true}
      loading={loading || debouncing}
      filterOptions={(x) => x}
      label={props.label ?? 'Search owner'}
      {...props}
      onInputChange={(_, value, reason) => {
        if (reason === 'input') {
          setSearch(value);
        } else {
          setSearch('');
        }
      }}
      onChange={(_, newValue) => {
        const newId = typeof newValue === 'string' ? newValue : newValue?.id;
        props.onChange?.(owners.find(({ id }) => id === newId));
      }}
    />
  );
};
