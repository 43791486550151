import { PaymentDetails } from 'api';
import { graphqlNull } from './constant';
import { NullableAllDefined } from './types';

export const toGraphqlPaymentDetails = (
  data: Partial<PaymentDetails>
): NullableAllDefined<PaymentDetails> => ({
  __typename: 'PaymentDetails',
  padAccount: data.padAccount ?? graphqlNull,
  padAmount: data.padAmount ?? graphqlNull,
  padInstitution: data.padInstitution ?? graphqlNull,
  padTransit: data.padTransit ?? graphqlNull,
  preferredPaymentMethod: data.preferredPaymentMethod ?? graphqlNull,
});
