export const emptyArray: [] = [];
export const graphqlNull = null as unknown as undefined;
export const concurrentMutations = 50;
export const defaultPrintNotes = false;
export const rentFeeId = '0_rent';
export const incentiveFeeId = '1_incentive';
export const maxISODate = '9999-12-31T23:59:59Z';
export const AUTOPAY_PAYEES_PAGE_SIZE = 100;
export const TENANTS_PAGE_SIZE = 100;
export const SUPPLIERS_PAGE_SIZE = 100;
export const REQUESTS_PAGE_SIZE = 100;
export const CLEARABLES_PAGE_SIZE = 100;
export const JOURNALS_PAGE_SIZE = 100;
export const MAX_API_PAGE_SIZE = 1000;
export const TIER_0_MAX_UNITS = 30;
export const TIER_0_MAX_UNITS_MESSAGE =
  'You have reached the maximum number of units for the Starter account. Upgrade to Essentials to add unlimited units.';
export const REFRESH_TIMEOUT_MS = 5_000;

export const AUTOPAY_INVITE_BLACKOUT_MINUTES = 5;
