import { GlAccountFieldsFragment } from 'api';
import { useMeta } from 'hooks/useMeta';
import { useTaxes } from 'hooks/useTaxes';
import { useTranslate } from 'hooks/useTranslate';
import { find } from 'lodash';
import { GLCategory } from 'pages/accounting/categories';
import { useCallback, useMemo } from 'react';
import { ensureArray } from 'system';
import { AccountType } from './../../../api/index';
import { useBooks } from './useBooks';
import { useGlAccounts } from './useGlAccounts';

interface CategoryGlAccounts {
  (category: GLCategory): GlAccountFieldsFragment[];
  (categoryArray: GLCategory[]): GlAccountFieldsFragment[];
}

interface TypedGlAccounts {
  (accountType: AccountType): GlAccountFieldsFragment[];
  (accountTypeArray: AccountType[]): GlAccountFieldsFragment[];
}

export const useGlMapping = () => {
  const { books, ...booksMeta } = useBooks();
  const { glAccounts, findGlAccount, ...glAccountsMeta } = useGlAccounts();
  const { accountAppliesPst } = useTaxes();
  const { t } = useTranslate();

  const glMapping = useMemo(
    () =>
      ensureArray(
        books?.glAccountMapping?.filter(
          (account) => account.id !== 'pstPayable' || accountAppliesPst
        )
      ).map((account) => ({
        ...account,
        name: account.translationKey ? t(account.translationKey) : account.name,
      })),
    [accountAppliesPst, books?.glAccountMapping]
  );

  const { loading } = useMeta(booksMeta, glAccountsMeta);

  const mappedGlId = useCallback((id: string) => find(glMapping, { id })?.glId, [glMapping]);

  const mappedGlAccount = useCallback(
    (id: string) => find(glAccounts, { id: find(glMapping, { id })?.glId }),
    [glAccounts, glMapping]
  );

  const anyClearableGlId = useCallback(
    (...glIds: string[]) => {
      const apId = mappedGlId('accountsPayable') ?? '';
      const arId = mappedGlId('accountsReceivable') ?? '';

      return glIds.some((glId) => glId && [apId, arId].includes(glId));
    },
    [mappedGlId]
  );

  const categoryGlAccounts: CategoryGlAccounts = useCallback(
    (category: GLCategory | GLCategory[]) =>
      glAccounts.filter((gl) =>
        Array.isArray(category)
          ? gl.category && category.includes(gl.category as GLCategory)
          : category === gl.category
      ),
    [glAccounts]
  );

  const typedGlAccounts: TypedGlAccounts = useCallback(
    (accountType: AccountType | AccountType[]) =>
      glAccounts.filter((gl) =>
        Array.isArray(accountType)
          ? accountType.includes(gl.accountType as AccountType)
          : accountType === gl.accountType
      ),
    [glAccounts]
  );

  return {
    loading,
    glMapping,
    mappedGlId,
    findGlAccount,
    mappedGlAccount,
    categoryGlAccounts,
    typedGlAccounts,
    anyClearableGlId,
  };
};
