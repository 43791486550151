import { Storage } from '@aws-amplify/storage';
import {
  ResidentRequestScheduling,
  TaxType,
  UpdateAccountInput,
  useGetAccountQuery,
  useUpdateAccountMutation,
} from 'api';
import { useGetProvinceTaxDetails } from 'hooks/useGetProvinceTaxDetails';
import { useMeta } from 'hooks/useMeta';
import { useModalControl } from 'hooks/useModalControl';
import { useNotification } from 'hooks/useNotification';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { addressShape } from 'system/shapes';
import { v4 as uuid } from 'uuid';
import { z } from 'zod';

export const accountFormFieldShape = z.object({
  name: z.string(),
  phone: z.string(),
  residentRequestScheduling: z.nativeEnum(ResidentRequestScheduling),
  address: addressShape.optional(),
  logo: z
    .custom<File>((v) => v instanceof File)
    .refine(
      (file) => file?.size ?? 0 <= 2 * 1024 * 1024,
      'The logo file size must be less than 2 MB'
    )
    .refine(
      (file) => !file || ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(file?.type),
      'Unsupported Format'
    )
    .optional(),
  logoPreview: z.string().optional(),
  logoKey: z.string().optional(),
});

export type AccountFormFields = z.infer<typeof accountFormFieldShape>;

enum Notifications {
  ACCOUNT_UPDATE = 'Your account has been successfully updated.',
  ACCOUNT_SETTINGS_UPDATE = 'Account settings have been successfully updated.',
}

export const useAccount = () => {
  const { sendNotification } = useNotification();
  const [showModal, hideModal, modalOpen] = useModalControl();

  const [updateAccountMutation, updateMeta] = useUpdateAccountMutation();
  useMeta(updateMeta);

  const { data, ...accountMeta } = useGetAccountQuery();
  const { loading } = useMeta(accountMeta);
  const account = data?.account;

  const { taxDetails, getTaxDetails, ...taxDetailsMeta } = useGetProvinceTaxDetails(
    account?.address && {
      province: account.address.province,
      country: account.address.country,
      postal: account.address.postal,
    }
  );
  useMeta(taxDetailsMeta);
  const gstInfo = useMemo(
    () => taxDetails.find((x) => [TaxType.Gst, TaxType.SalesTax].includes(x.taxType) && x.rate),
    [taxDetails]
  );

  const updateAccount = async ({
    name,
    phone,
    residentRequestScheduling,
    address,
    logo,
    logoKey,
  }: AccountFormFields) => {
    if (!account) {
      console.error('No account found');
      return;
    }

    const updatedLogoKey = logo ? `accounts/${account.id}/${uuid()}-${logo.name}` : (logoKey ?? '');
    logo &&
      (await Storage.put(updatedLogoKey, logo, {
        bucket: process.env.REACT_APP_PUBLIC_BUCKET,
        acl: 'public-read',
      }));

    const input: UpdateAccountInput = {
      name,
      phone,
      settings: {
        ...account.settings,
        resident_request_scheduling:
          residentRequestScheduling ?? ResidentRequestScheduling.Preferences,
      },
      address,
      logoKey: updatedLogoKey,
    };

    await updateAccountMutation({
      variables: {
        id: account.id,
        input,
      },
    });

    hideModal();
    sendNotification(Notifications.ACCOUNT_UPDATE, 'success');
  };

  const updateExpiryThreshold = async ({
    soonDaysThreshold,
    laterDaysThreshold,
  }: {
    soonDaysThreshold?: number;
    laterDaysThreshold?: number;
  }) => {
    if (!account) {
      console.error('No account found');
      return;
    }
    const input = {
      settings: {
        ...account.settings,
        soon_days_threshold: soonDaysThreshold,
        later_days_threshold: laterDaysThreshold,
      },
    };

    await updateAccountMutation({
      variables: {
        id: account.id,
        input,
      },
    });

    hideModal();
    sendNotification(Notifications.ACCOUNT_UPDATE, 'success');
  };

  const updateCampaignApplicationRules = async ({
    requiredDocs,
  }: {
    requiredDocs?: {
      quantity: number;
      docTypes: string[];
    }[];
  }) => {
    if (!account) {
      console.error('No account found');
      return;
    }
    const input = {
      settings: {
        ...account.settings,
        campaignApplicationRules: { requiredDocs },
      },
    };

    await updateAccountMutation({
      variables: {
        id: account.id,
        input,
      },
    });

    hideModal();
    sendNotification(Notifications.ACCOUNT_SETTINGS_UPDATE, 'success');
  };

  const showGstHst = (account?.address?.country ?? 'CA') === 'CA';
  const { t } = useTranslation('translation', {
    lng: `en-${account?.address?.country ?? 'CA'}`,
  });

  return {
    loading,
    account,
    accountId: account?.id,
    accountName: account?.name,
    updateAccount,
    updateExpiryThreshold,
    updateCampaignApplicationRules,
    showModal,
    hideModal,
    modalOpen,
    gstRate: gstInfo?.rate,
    gstName: gstInfo?.label,
    showGstHst,
    t,
  };
};
