import { Alert, Link } from '@mui/material';
import { useAuth } from 'context';
import plur from 'plur';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { SettingsTabs } from '../financial-settings/FinancialSettingsTabForm';
import { useGlMapping } from './useGlMapping';

export const useFinancialSettingsBanner = () => {
  const { loading, glMapping } = useGlMapping();
  const [banner, setBanner] = useState<ReactNode>();
  const { isBooksAdmin } = useAuth();
  const location = useLocation();
  const isFinancialSettingsSetup = location.pathname === '/accounting/financial-settings-setup';

  const unmapped = useMemo(
    () => glMapping.filter((mapping) => !mapping.glId || mapping.glId === ''),
    [glMapping]
  );
  const hasUnmapped = Boolean(unmapped.length);

  useEffect(() => {
    if (loading) {
      return;
    }
    setBanner(
      hasUnmapped && !isFinancialSettingsSetup ? (
        <Alert severity="warning" sx={{ flex: 1 }}>
          You have {unmapped.length} {plur('item', unmapped.length)} in financial settings not yet
          mapped to a GL account.
          {isBooksAdmin ? (
            <p>
              Please go to the{' '}
              <Link
                component={RouterLink}
                to="/accounting/financial-settings"
                state={{ financialSettingsTab: SettingsTabs.ACCOUNT_MAPPING }}
              >
                Financial Settings
              </Link>{' '}
              page to configure mapping for:
              <ul>
                {unmapped.map((mapping) => (
                  <li key={mapping.id}>{mapping.name}</li>
                ))}
              </ul>
            </p>
          ) : (
            <p>
              Please contact your accounting administrator to map them in order to ensure full
              functionality.
            </p>
          )}
        </Alert>
      ) : (
        <></>
      )
    );
  }, [isBooksAdmin, loading, unmapped, hasUnmapped]);

  return banner;
};
